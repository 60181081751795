import { BACKEND_URL } from 'src/infra/http/httpClient'

const complaintsPrefix = 'complaints.'

export const ComplaintsSaveComplaint = `${BACKEND_URL}${complaintsPrefix}saveComplaint`
export const ComplaintsUpdateComplaint = `${BACKEND_URL}${complaintsPrefix}updateComplaint`
export const ComplaintsSaveComplaintComment = `${BACKEND_URL}${complaintsPrefix}saveComplaintComment`
export const ComplaintsUpdateComplaintComment = `${BACKEND_URL}${complaintsPrefix}updateComplaintComment`
export const ComplaintsSaveComplaintInfoRequest = `${BACKEND_URL}${complaintsPrefix}saveComplaintInfoRequest`
export const ComplaintsUpdateComplaintInfoRequest = `${BACKEND_URL}${complaintsPrefix}updateComplaintInfoRequest`
export const ComplaintsSaveAssignedUser = `${BACKEND_URL}${complaintsPrefix}saveComplaintAssignedUser`
export const ComplaintsSaveAttachment = `${BACKEND_URL}${complaintsPrefix}saveComplaintAttachment`
export const ComplaintsUpdateAttachment = `${BACKEND_URL}${complaintsPrefix}updateComplaintAttachment`
