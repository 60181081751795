import { fFormatDate, Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementPaidPriorDataParams {
  data: any
}
const PostPlacementPaidPriorData: React.FC<
  PostPlacementPaidPriorDataParams
> = ({ data }) => {
  return (
    <Grid>
      <Typography>
        Paid Prior Date: {fFormatDate(data?.PaidPriorDate)}
      </Typography>
      <Typography>Type: {data?.PaidPriorType}</Typography>
    </Grid>
  )
}

export default PostPlacementPaidPriorData
