import { Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementRetainDataParams {
  data: any
}
const PostPlacementRetainData: React.FC<PostPlacementRetainDataParams> = ({
  data,
}) => {
  return (
    <Grid>
      <Typography>Explanation: {data?.Explanation}</Typography>
      <Typography>Number Of Days: {data?.NumberOfDays}</Typography>
    </Grid>
  )
}

export default PostPlacementRetainData
