import { Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementExplanationDataParams {
  data: any
}
const PostPlacementExplanationData: React.FC<
  PostPlacementExplanationDataParams
> = ({ data }) => {
  return (
    <Grid>
      <Typography>Explanation: {data?.Explanation}</Typography>
    </Grid>
  )
}

export default PostPlacementExplanationData
