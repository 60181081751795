/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import {
  Accordion,
  FormDropdown,
  FormTextArea,
  Grid,
  Icon,
} from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { ValueLabelDto } from 'src/domain/models/common'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { ComplaintDto } from 'src/domain/models/complaint'

interface ComplaintComplianceAccordionProps {
  complaintGetOperations?: IComplaintGetOperations
  accountGetOperations?: IAccountGetOperations
  complaintDto?: ComplaintDto
  accountStatusesData?: ValueLabelDto[]
  isLoading?: boolean
  isLoadingAccountStatusesData?: boolean
  methods: any
}

const ComplaintComplianceAccordion: React.FC<
  ComplaintComplianceAccordionProps
> = ({
  complaintGetOperations,
  accountGetOperations,
  complaintDto,
  accountStatusesData,
  isLoading,
  isLoadingAccountStatusesData,
  methods,
}) => {
  const {
    data: complaintStatusesData,
    isLoading: isLoadingComplaintStatusesData,
  } = useCustomQuery(
    ['getComplaintStatuses'],
    async () => complaintGetOperations?.getComplaintStatuses(),
    { cacheTime: 0, enabled: true }
  )

  const { data: violationTypesData, isLoading: isLoadingViolationTypesData } =
    useCustomQuery(
      ['getComplaintViolationTypes'],
      async () => complaintGetOperations?.getComplaintViolationTypes(),
      { cacheTime: 0, enabled: true }
    )

  const { data: outcomesData, isLoading: isLoadingOutcomesData } =
    useCustomQuery(
      ['getComplaintOutcomes'],
      async () => complaintGetOperations?.getComplaintOutcomes(),
      { cacheTime: 0, enabled: true }
    )

  const { data: accountVendorsData, isLoading: isLoadingAccountVendorsData } =
    useCustomQuery(
      ['getVendorsAccount'],
      async () =>
        accountGetOperations?.getVendorsAccount(complaintDto?.accountId!),
      { cacheTime: 0, enabled: !!complaintDto?.accountId }
    )

  return (
    <Accordion title="Compliance" icon={<Icon name="Info" />}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormDropdown
            name="status"
            placeholder="Status"
            options={complaintStatusesData ?? []}
            width="100%"
            isLoading={isLoadingComplaintStatusesData || isLoading}
            allowEmptyValue={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDropdown
            name="vendorId"
            placeholder="Vendor"
            options={accountVendorsData ?? []}
            width="100%"
            isLoading={isLoadingAccountVendorsData || isLoading}
            allowEmptyValue={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDropdown
            name="accountStatus"
            placeholder="Account Status"
            options={accountStatusesData ?? []}
            width="100%"
            isLoading={isLoadingAccountStatusesData || isLoading}
            allowEmptyValue={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDropdown
            name="violationType"
            placeholder="Violation Type"
            options={violationTypesData ?? []}
            width="100%"
            isLoading={isLoadingViolationTypesData || isLoading}
            allowEmptyValue={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDropdown
            name="outcome"
            placeholder="Final Outcome"
            options={outcomesData ?? []}
            width="100%"
            isLoading={isLoadingOutcomesData || isLoading}
            allowEmptyValue={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextArea
            name="complaintResolution"
            placeholder="Resolution"
            width="100%"
            skeletonWidth="100%"
            isLoading={isLoading}
            minHeight="unset"
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextArea
            name="debtTraderActions"
            placeholder="Everchain Actions"
            width="100%"
            skeletonWidth="100%"
            isLoading={isLoading}
            minHeight="unset"
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}

export default ComplaintComplianceAccordion
