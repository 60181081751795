import { fFormatDate, Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementBankruptDataParams {
  data: any
}
const PostPlacementBankruptData: React.FC<PostPlacementBankruptDataParams> = ({
  data,
}) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={6}>
        <Typography>Case Number: {data?.CaseNumber}</Typography>
        <Typography>Chapter: {data?.Chapter}</Typography>
        <Typography>File Date: {fFormatDate(data?.FileDate)}</Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Typography>Attorney Name: {data?.AttorneyName}</Typography>
        <Typography>Attorney Phone: {data?.AttorneyPhone}</Typography>
        <Typography>District: {data?.District}</Typography>
      </Grid>
    </Grid>
  )
}

export default PostPlacementBankruptData
