import { Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementTypeAndExplanationDataParams {
  data: any
}
const PostPlacementTypeAndExplanationData: React.FC<
  PostPlacementTypeAndExplanationDataParams
> = ({ data }) => {
  return (
    <Grid>
      <Typography>Type: {data?.Type}</Typography>
      <Typography>Explanation: {data?.Explanation}</Typography>
    </Grid>
  )
}

export default PostPlacementTypeAndExplanationData
