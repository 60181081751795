export const BASE_ROUTE = '/recover'

export const HOME = '/'
export const LOGIN = '/login'
export const SEARCH = '/search'
export const ACCESSDENIED = '/accessdenied'
export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
export const ACCOUNTS = '/accounts'
export const ACCOUNT_FILE_VALIDATION = ACCOUNTS + '/file-validation'
export const STRATEGIES = '/strategies'
export const STRATEGY = '/strategy'
export const ACCOUNT_DETAIL = '/account-detail'
export const RECALL_ACCOUNT_FILE_VALIDATION =
  ACCOUNTS + '/recall-file-validation'
export const TRANSACTION_FILE_VALIDATION =
  ACCOUNTS + '/transaction-file-validation'
export const BUSINESS_SETTINGS = '/business-settings'
export const UPDATE_ACCOUNT_FILE_VALIDATION =
  ACCOUNTS + '/update-file-validation'
export const RESTING_AREA = '/resting-area'
export const CHARGE_OFF_STRATEGY_ACCOUNT_FILE_VALIDATION =
  ACCOUNTS + '/charge-off-file-validation'
export const CHARGE_OFF_RESTING_ACCOUNT_FILE_VALIDATION =
  RESTING_AREA + '/charge-off-file-validation'
export const POST_PLACEMENT = ACCOUNTS + '/post-placement'
export const POST_PLACEMENT_MANAGER = ACCOUNTS + '/post-placement-manager'
export const BULK_POST_PLACEMENT = ACCOUNTS + '/bulk-post-placement'
export const BULK_POST_PLACEMENT_RESULT = BULK_POST_PLACEMENT + '/result'
export const COMPLAINT = '/complaint'
export const COMPLAINT_CREATE = COMPLAINT + '/create'
export const COMPLAINT_INFO = COMPLAINT + '/info'
export const COMPLAINT_INFO_NEW = COMPLAINT_INFO + '/new'
export const PARAMETER_MANAGER = '/parameter-manager'
