/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Dropdown,
  ModalDialog,
  SuccessToast,
  TextArea,
} from 'everchain-uilibrary'
import { ClientUsersByBusiness } from 'src/domain/models/clients'
import { ComplaintDto } from 'src/domain/models/complaint'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { useQueryClient } from '@tanstack/react-query'

interface ComplaintAssignUserModalProps {
  isOpen: boolean
  complaintDto?: ComplaintDto
  clientUsers?: ClientUsersByBusiness[]
  loadingClientUsers?: boolean
  complaintPostOperations?: IComplaintPostOperations
  setIsOpen: (open: boolean) => void
}

const MAX_CHAR_LIMIT_REASON = 400

const ComplaintAssignUserModal: React.FC<ComplaintAssignUserModalProps> = ({
  isOpen,
  complaintDto,
  clientUsers,
  loadingClientUsers,
  complaintPostOperations,
  setIsOpen,
}) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
  const [reason, setReason] = useState<string | undefined>()
  const [userId, setUserId] = useState<string | undefined>()
  const queryClient = useQueryClient()

  const handleOnClickSave = () => {
    if ((complaintDto?.assignedUserId && !reason) || !userId) {
      setHasError(true)
      return
    }

    setHasError(false)
    setLoadingSaveButton(true)

    complaintPostOperations
      ?.saveComplaintAssignedUser({
        complaintId: complaintDto?.id!,
        userId,
        reason,
      })
      .then(() => {
        SuccessToast('Compliance Analyst assigned to the complaint')
        setReason('')
        setIsOpen(false)
        queryClient.refetchQueries({
          queryKey: ['getComplaintById'],
        })
      })
      .finally(() => {
        setLoadingSaveButton(false)
      })
  }

  useEffect(() => {
    setUserId(complaintDto?.assignedUserId?.toUpperCase())
  }, [complaintDto?.assignedUserId])

  return (
    <ModalDialog
      isOpen={isOpen}
      header="Assign Complaint to an Compliance Analyst"
      onClose={() => {
        setIsOpen(false)
        setReason('')
        setUserId(complaintDto?.assignedUserId?.toUpperCase())
      }}
      onContinue={handleOnClickSave}
      buttonOkText="Assign"
      width="50%"
      isFetching={loadingSaveButton}
      disableOkButton={
        (hasError && !reason) ||
        userId?.toUpperCase() === complaintDto?.assignedUserId?.toUpperCase()
      }
    >
      <Box>
        <Dropdown
          allowEmptyValue={false}
          options={clientUsers ?? []}
          labelOptionName="name"
          valueOptionName="id"
          width="100%"
          maxwidth="300px"
          isLoading={loadingClientUsers}
          onChange={(option) => setUserId(option.id)}
          value={userId}
        />
        {complaintDto?.assignedUserId && (
          <TextArea
            placeholder="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            width="100%"
            variant="secondary"
            errormessage={
              hasError && !reason ? 'Reason is required' : undefined
            }
            skeletonWidth="100%"
            maxLength={MAX_CHAR_LIMIT_REASON}
          />
        )}
      </Box>
    </ModalDialog>
  )
}

export default ComplaintAssignUserModal
