import React from 'react'
import { makeAssetTypesData } from 'src/main/factories/feature/get/AssetTypes'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeStrategyData } from 'src/main/factories/feature/get/Strategy'
import { makeStrategyPostData } from 'src/main/factories/feature/post/Strategy'
import makeParameterManagerValidation from 'src/main/factories/validation/parameterManager'
import ParameterManager from 'src/presentation/pages/Strategies/ParameterManager'

const makeParameterManager: React.FC = () => {
  return (
    <ParameterManager
      businessOperations={makeBusinessGetData()}
      assetTypeOperations={makeAssetTypesData()}
      strategyGetOperations={makeStrategyData()}
      strategyPostOperations={makeStrategyPostData()}
      validation={makeParameterManagerValidation}
    />
  )
}

export default makeParameterManager
