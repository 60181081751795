import { Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementCreditBureauReportingDataParams {
  data: any
}
const PostPlacementCreditBureauReportingData: React.FC<
  PostPlacementCreditBureauReportingDataParams
> = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={6}>
        <Typography>Type: {data?.CreditBureauType}</Typography>
        <Typography>Tradeline Added: {data?.TradelineAdded}</Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Typography>Tradeline Removed: {data?.TradelineRemoved}</Typography>
        <Typography>Explanation: {data?.Explanation}</Typography>
      </Grid>
    </Grid>
  )
}

export default PostPlacementCreditBureauReportingData
