import { DataTableState } from 'everchain-uilibrary'
import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { DataResponse } from 'src/domain/models/common'
import {
  Strategy,
  StrategyParameters,
  StrategyStage,
} from 'src/domain/models/strategy'
import { getCurrentPage } from 'src/utils/grid'
import {
  StrategyGetAllStrategies,
  StrategyGetStrategies,
  StrategyGetStrategiesByChargeOffTypeAndBusinessIds,
  StrategyGetStrategiesParameters,
  StrategyGetStrategyByCreditorId,
  StrategyGetStrategyById,
  StrategyGetStrategyStage,
  StrategyStageTotalDistribution,
} from './urls'

export class StrategyGetData implements IStrategyGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getTotalDistribution(
    strategyId: number,
    strategyStageId: number
  ): Promise<number> {
    const response = await this.httpClient.get(
      `${StrategyStageTotalDistribution}?strategyId=${strategyId}&strategyStageId=${strategyStageId}`
    )
    return response.data
  }
  async getStrategyById(id: number): Promise<Strategy> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyById}?id=${id}`
    )
    return response.data
  }

  async getStrategies(
    pagination: DataTableState,
    creditorId?: string,
    onlyEnabled?: boolean,
    withAccountPlaced?: boolean
  ): Promise<DataResponse<Strategy>> {
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const response = await this.httpClient.get(
      `${StrategyGetStrategies}?kendoPagination=${JSON.stringify(
        pagination
      )}&pageNumber=${getCurrentPage(pagination)}&pageSize=${
        pagination.take
      }&onlyEnabled=${onlyEnabled}${creditorFilter}`
    )
    return response.data
  }

  async getStrategyStageByStrategyId(
    pagination: DataTableState,
    strategyId: number
  ): Promise<DataResponse<StrategyStage>> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyStage}?pageNumber=${getCurrentPage(
        pagination
      )}&pageSize=${pagination.take}&strategyId=${strategyId}`
    )
    return response.data
  }

  async getStrategyByCreditorId(creditorId: string): Promise<Strategy[]> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyByCreditorId}?creditorId=${creditorId}`
    )
    return response.data
  }

  async getStrategiesParameters(
    pagination: DataTableState,
    creditors: string[]
  ): Promise<StrategyParameters[]> {
    const relatedCreditors = creditors
      ? `&relatedCreditors=${JSON.stringify(creditors)} `
      : ''
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${StrategyGetStrategiesParameters}?${relatedCreditors}&pagination=${encodedData}`
    )
    return response.data
  }

  async getAllStrategies(): Promise<Strategy[]> {
    const response = await this.httpClient.get(StrategyGetAllStrategies)
    return response.data
  }

  async getStrategiesByChargeOffTypeAndBusinessIds(
    businessIds: string[],
    chargeOffType: number
  ): Promise<Strategy[]> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategiesByChargeOffTypeAndBusinessIds}?businessIds=${JSON.stringify(
        businessIds
      )}&chargeOffType=${chargeOffType}`
    )
    return response.data
  }
}
