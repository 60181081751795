import { ValidationRulesDictionary, ValidationRules } from 'everchain-uilibrary'

const makeStrategyDistributionValidation = (
  maxValue = 100
): ValidationRulesDictionary => {
  return {
    vendorId: new ValidationRules().required('Vendor is required'),
    distribution: new ValidationRules()
      .required('Distribution is required')
      .max(maxValue, 'The distribution value cannot be greater than 100%')
      .min(0.1, 'The distribution value needs to be greater than 0%'),
    fee: new ValidationRules()
      .required('Fee is required')
      .max(100, 'The fee value cannot be greater than 100%')
      .min(0.1, 'The fee value needs to be greater than 0%'),
  }
}

export default makeStrategyDistributionValidation
