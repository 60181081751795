/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'everchain-uilibrary'

import { Skeleton, TextField } from '@mui/material'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'

interface Options {
  label: string
  value: string
}
interface PostPlacementTypesParams {
  businessOperations?: IBusinessGetOperations
  onTypesUpdate: (types: any, typeName?: any) => void
  selectedType?: string
}

const PostPlacementTypes: React.FC<PostPlacementTypesParams> = ({
  businessOperations,
  onTypesUpdate,
  selectedType = '',
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [types, setTypes] = useState<any>()
  const [type, setType] = useState<string | undefined>(selectedType)

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.name,
        value: `${item.id}#${item.name}`,
      })
    )
    return newOptions
  }

  const GetTypes = async () => {
    setLoading(true)
    try {
      const typesResult = await businessOperations?.getPostPlacementTypesData()
      setTypes(typesResult)
      const typeOptions = getDropDownOptions(typesResult ?? [])
      if (typesResult?.length === 1)
        onTypesUpdate(typeOptions[0]?.value, typeOptions[0]?.label)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const options = getDropDownOptions(types || [])

  useEffect(() => {
    GetTypes()
  }, [])

  if (loading) return <Skeleton width="380px" height={80} />
  return !options || options?.length === 0 ? (
    <></>
  ) : options && options.length > 1 ? (
    <Dropdown
      id="post-placement-type-dropdown"
      maxwidth="350px"
      width="350px"
      options={options}
      value={type || undefined}
      onChange={(option?: Options) => {
        setType(option?.value)
        onTypesUpdate(option?.value, option?.label)
      }}
      placeholder="Select a type"
    />
  ) : (
    <TextField
      style={{ width: '350px', maxWidth: '350px' }}
      data-test-id="input-post-placement-type"
      id="input-post-placement-type"
      disabled={true}
      fullWidth
      label="Type"
      name="type"
      variant="standard"
      value={options[0].label ?? ''}
    />
  )
}

export default PostPlacementTypes
