import { Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementInfoDataParams {
  data: any
}
const PostPlacementInfoData: React.FC<PostPlacementInfoDataParams> = ({
  data,
}) => {
  return (
    <Grid>
      <Typography>Type: {data?.InfoType}</Typography>
      <Typography>Requested Information: {data?.InfoRequested}</Typography>
    </Grid>
  )
}

export default PostPlacementInfoData
