/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState } from 'react'
import {
  Accordion,
  Button,
  DataTable,
  DataTableState,
  Dropdown,
  ErrorToast,
  Flex,
  Group,
  Icon,
  ModalDialog,
  SuccessToast,
  TextArea,
  Typography,
  UploadDragDrop,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { complaintAttachmentColumns } from './gridColumns'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { AuthContext } from 'src/context/AuthenticationContext'

interface ComplaintAttachmentsAccordionProps {
  complaintGetOperations?: IComplaintGetOperations
  complaintPostOperations?: IComplaintPostOperations
  complaintId?: number
  isLoading?: boolean
}

const MAX_SIZE_FILE = 104857600 // 100MB

const ComplaintAttachmentsAccordion: React.FC<
  ComplaintAttachmentsAccordionProps
> = ({
  complaintGetOperations,
  complaintPostOperations,
  complaintId,
  isLoading,
}) => {
  const [attachmentId, setAttachmentId] = useState<number | undefined>(
    undefined
  )
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [infoRequestId, setInfoRequestId] = useState<number | undefined>(
    undefined
  )
  const [files, setFiles] = useState<File[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(false)

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const gridState: DataTableState = {
    skip: 0,
    take: 100,
  }

  const {
    data: attachmentsData,
    isFetching: loadingAttachmentsData,
    refetch: refetchGetQuery,
  } = useCustomQuery(
    ['getComplaintAttachments'],
    async () => complaintGetOperations?.getComplaintAttachments(complaintId!),
    { cacheTime: 0, enabled: !!complaintId }
  )

  const { data: infoRequestData, isFetching: loadingInfoRequestData } =
    useCustomQuery(
      ['getComplaintInfoRequests'],
      async () =>
        complaintGetOperations
          ?.getComplaintInfoRequests(complaintId!, gridState)
          .then((result) => {
            if (result?.data?.length) {
              setInfoRequestId(result.data[0].id)
            }

            return result
          }),
      { cacheTime: 0, enabled: !!complaintId }
    )

  const handleOnClickSave = () => {
    if (!openEditModal) {
      if (!description || !complaintId || !infoRequestId) {
        setHasError(true)
        return
      }

      if (!files.length) {
        ErrorToast('File is required')
        return
      }
    }

    setHasError(false)
    setLoadingSaveButton(true)

    if (attachmentId) {
      complaintPostOperations
        ?.updateComplaintAttachment({ id: attachmentId, isPublic })
        .then(() => {
          SuccessToast('Complaint attachment updated successfully')
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
          setAttachmentId(undefined)
          setOpenEditModal(false)
        })
    } else {
      complaintPostOperations
        ?.saveComplaintAttachment(
          complaintId!,
          infoRequestId!,
          files[0],
          description!
        )
        .then(() => {
          SuccessToast('Complaint attachment created successfully')
          setDescription('')
          setFiles([])
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
        })
    }
  }

  const handleEditButton = (id: number) => {
    const attachmentRecord = attachmentsData?.find((x) => x.id === id)

    setAttachmentId(id)
    setIsPublic(!attachmentRecord?.isPublic)
    setOpenEditModal(true)
  }

  const handleDownloadButton = (id: number) => {
    setLoadingDownload(true)
    complaintGetOperations
      ?.getUriComplaintAttachment(id)
      .then((result) => {
        if (result === '') return
        window.location.href = result
      })
      .finally(() => {
        setLoadingDownload(false)
      })
  }

  return (
    <Accordion title="Files" icon={<Icon name="CloudDownload" />}>
      <Group flexDirection="column">
        <Flex justifyContent="flex-end">
          <Button
            leftIcon={<Icon name="Add" fontSize="small" />}
            type="button"
            onClick={handleOnClickSave}
            isFetching={!openEditModal && loadingSaveButton}
            isLoading={isLoading}
            disabled={
              hasError && !description && !infoRequestId && !files.length
            }
          >
            Add Attachment
          </Button>
        </Flex>
        <Dropdown
          width="100%"
          placeholder="Related Info Request"
          options={infoRequestData?.data ?? []}
          value={infoRequestId}
          valueOptionName="id"
          labelOptionName="description"
          isLoading={loadingInfoRequestData || isLoading}
          allowEmptyValue={false}
          errormessage={
            hasError && !infoRequestId && !openEditModal
              ? 'Related Info Request is required'
              : undefined
          }
          onChange={(option) => setInfoRequestId(option.id)}
        />
        <TextArea
          placeholder="Description"
          value={!openEditModal ? description : ''}
          onChange={(e) => setDescription(e.target.value)}
          width="100%"
          variant="secondary"
          isLoading={isLoading}
          skeletonWidth="100%"
          errormessage={
            hasError && !description && !openEditModal
              ? 'Description is required'
              : undefined
          }
        />
        <UploadDragDrop
          files={files}
          setFiles={setFiles}
          isLoading={isLoading}
          hideUploadButton={true}
          maxSize={MAX_SIZE_FILE}
          accept={{
            // Documents
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              ['.docx'],
            'application/msword': ['.doc'],
            'text/plain': ['.txt'],
            'application/vnd.oasis.opendocument.text': ['.odt'],

            // Spreadsheets
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],

            // Presentations
            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
              ['.pptx'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.oasis.opendocument.presentation': ['.odp'],
          }}
        />
        <DataTable
          isLoading={loadingAttachmentsData || isLoading}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintAttachmentColumns(
            handleEditButton,
            handleDownloadButton,
            infoRequestData?.data ?? [],
            isInternal,
            loadingDownload
          )}
          data={attachmentsData ?? []}
        />
      </Group>
      <ModalDialog
        header="Change Visibility"
        isOpen={openEditModal}
        onClose={() => {
          setDescription('')
          setAttachmentId(undefined)
          setOpenEditModal(false)
        }}
        buttonOkText={`Make ${isPublic ? 'Public' : 'Private'}`}
        onContinue={handleOnClickSave}
        isFetching={loadingSaveButton}
        typeOkButton="button"
      >
        <Typography>
          {`This action will make this comment ${
            isPublic ? 'public' : 'private'
          }. Are you sure?`}
        </Typography>
      </ModalDialog>
    </Accordion>
  )
}

export default ComplaintAttachmentsAccordion
