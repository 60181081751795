import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { PostPlacementAddComment, PostPlacementCreateRequest } from './urls'

export class PostPlacementPostData implements IPostPlacementPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async uploadBulkFile(
    requestType: number | undefined,
    data: any[]
  ): Promise<any> {
    return this.httpClient.post(PostPlacementCreateRequest, {
      requestType: Number(requestType),
      data,
    })
  }

  async addComment(requestId: number, comment: string): Promise<boolean> {
    return this.httpClient.post(PostPlacementAddComment, {
      requestId: Number(requestId),
      comment,
    })
  }
}
