import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import * as urls from './urls'
import * as types from 'src/domain/models/clients'
import { IClientGetOperations } from 'src/domain/features/get/clients/clients'

export class ClientGetData implements IClientGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getUserBusiness(
    userId: string | undefined
  ): Promise<types.ClientUsersByBusiness[]> {
    const response = await this.httpClient.get(
      `${urls.ClientGetUsersByUserBusiness}?userId=${userId}`
    )
    return response?.data
  }
}
