import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { ApiError } from 'src/domain/models/api/api'
import {
  UpdateStrategyStatus,
  CloneStrategy,
  DeleteStrategy,
  DeleteStrategyStages,
  Strategy,
  StrategyStageDistribution,
  StrategyStage,
  SaveStrategyParameters,
} from 'src/domain/models/strategy'

import { BACKEND_URL } from 'src/infra/http/httpClient'

export class StrategyPostData implements IStrategyPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async cloneStrategy(
    params: CloneStrategy
  ): Promise<CloneStrategy | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.cloneStrategy`,
      params
    )
  }

  async updateStrategyStatus(
    params: UpdateStrategyStatus
  ): Promise<UpdateStrategyStatus | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.updateStrategyStatus`,
      params
    )
  }

  async editStrategy(strategy: Strategy): Promise<Strategy | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.updateStrategy`,
      strategy
    )
  }

  async addStrategy(strategy: Strategy): Promise<Strategy | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.saveStrategy`,
      strategy
    )
  }

  async deleteStrategy(
    params: DeleteStrategy
  ): Promise<DeleteStrategy | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.deleteStrategy?strategyId=${params.strategyId}`
    )
  }

  async addStrategyStageDistribution(
    strategyStage: StrategyStageDistribution
  ): Promise<StrategyStageDistribution | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.saveStrategyStageDistribution`,
      strategyStage
    )
  }

  async addStrategyStages(
    strategyStage: StrategyStage
  ): Promise<StrategyStage | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.saveStrategyStage`,
      strategyStage
    )
  }

  async editStrategyStages(
    strategyStage: StrategyStage
  ): Promise<StrategyStage | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.updateStrategyStage`,
      strategyStage
    )
  }

  async deleteStrategyStages(
    params: DeleteStrategyStages
  ): Promise<DeleteStrategyStages | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.deleteStrategyStage?id=${params.id}`
    )
  }

  async deleteStrategyDistribution(
    params: DeleteStrategyStages
  ): Promise<DeleteStrategyStages | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.deleteStrategyStageDistribution?id=${params.id}`
    )
  }
  async updateStrategyStageDistribution(
    strategyStage: StrategyStageDistribution
  ): Promise<StrategyStageDistribution | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.updateStrategyStageDistribution`,
      strategyStage
    )
  }

  async saveStrategyParameters(
    params: SaveStrategyParameters
  ): Promise<SaveStrategyParameters | ApiError> {
    return this.httpClient.post(
      `${BACKEND_URL}strategies.saveStrategyParameters`,
      params
    )
  }
}
