import {
  ValidationRules,
  ValidationRulesDictionary,
} from 'src/validation/validation'

const makeParameterManagerValidation: ValidationRulesDictionary = {
  assetTypeId: new ValidationRules().required('Asset Type is required'),
  minimumPaymentValue: new ValidationRules().functionValidation(
    (value: any, context?: Record<string, any>) => {
      const { minimumPaymentValue, minimumPaymentPercent } = context || {}
      if (!minimumPaymentValue && !minimumPaymentPercent) {
        return 'Either Minimum Payment Money or Minimum Payment Percent must have a value.'
      }
      return true // Pass validation
    }
  ),
  minimumPaymentPercent: new ValidationRules().functionValidation(
    (value: any, context?: Record<string, any>) => {
      const { minimumPaymentValue, minimumPaymentPercent } = context || {}
      if (!minimumPaymentValue && !minimumPaymentPercent) {
        return 'Either Minimum Payment Money or Minimum Payment Percent must have a value.'
      }
      return true // Pass validation
    }
  ),
  daysIncrease: new ValidationRules()
    .required('Days Increase is required')
    .min(1, 'Days Increase must be greater than 0.'),
  strategyId: new ValidationRules().functionValidation(
    (value: any, context?: Record<string, any>) => {
      const { strategyId, makeDefault } = context || {}
      if (!makeDefault && !strategyId) {
        return 'Strategy is required when Make Default is unchecked.'
      }
      return true
    }
  ),
}
export default makeParameterManagerValidation
