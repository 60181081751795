import React from 'react'
import { makeFilesData } from 'src/main/factories/feature/get/Files'
import { makeAccountPostData } from 'src/main/factories/feature/post/Account'
import ChargeOffStrategyAccountFileValidation from 'src/presentation/pages/Accounts/ChargeOffStrategyAccountFileValidation/ChargeOffStrategyAccountFileValidation'

const makeChargeOffStrategyAccountFileValidation: React.FC = () => {
  return (
    <ChargeOffStrategyAccountFileValidation
      fileOperations={makeFilesData()}
      accountPostOperations={makeAccountPostData()}
    />
  )
}

export default makeChargeOffStrategyAccountFileValidation
