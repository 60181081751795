import {
  Badge,
  Button,
  CircularProgress,
  Colors,
  Flex,
  Icon,
  IconButton,
  renderDateTime,
  renderDescriptionList,
  renderYesOrNoBoolean,
} from 'everchain-uilibrary'
import React from 'react'
import { ComplaintInfoRequestDto } from 'src/domain/models/complaint'
import { TdNoEllipsis } from 'src/presentation/styles/layout'

export const complaintCommentColumns = (
  onClickEdit: (id: number) => void,
  isInternal: boolean
) => [
  {
    field: 'text',
    title: 'Comment',
    show: true,
    width: 245,
  },
  {
    field: 'createdAt',
    title: 'Created At',
    show: true,
    width: 150,
    render: renderDateTime,
  },
  {
    field: 'isPublic',
    title: 'Public',
    show: true,
    width: 74,
    notFilterable: true,
    render: renderYesOrNoBoolean,
  },
  {
    title: 'Edit',
    show: isInternal,
    width: 60,
    notFilterable: true,
    render: (props: any) => {
      return (
        <TdNoEllipsis>
          <IconButton onClick={() => onClickEdit(props.dataItem.id)}>
            <Icon name="Edit" />
          </IconButton>
        </TdNoEllipsis>
      )
    },
  },
]

export const complaintInfoRequestColumns = (
  onClickEdit: (id: number) => void,
  isInternal: boolean
) => [
  {
    field: 'status',
    title: 'Status',
    show: true,
    width: 85,
  },
  {
    field: 'description',
    title: 'Description',
    show: true,
    width: 200,
  },
  {
    field: 'createdAt',
    title: 'Created At',
    show: true,
    width: 150,
    render: renderDateTime,
  },
  {
    title: 'Edit',
    show: isInternal,
    width: 60,
    notFilterable: true,
    render: (props: any) => {
      return (
        <TdNoEllipsis>
          <IconButton onClick={() => onClickEdit(props.dataItem.id)}>
            <Icon name="Edit" />
          </IconButton>
        </TdNoEllipsis>
      )
    },
  },
]

export const complaintAttachmentColumns = (
  onClickEdit: (id: number) => void,
  onClickDownloadButton: (id: number) => void,
  infoRequests: ComplaintInfoRequestDto[],
  isInternal: boolean,
  loadingDownload: boolean
) => [
  {
    field: 'createdAt',
    title: 'Created At',
    show: true,
    width: 150,
    render: renderDateTime,
  },
  {
    field: 'infoRequestId',
    title: 'Request Info',
    show: true,
    width: 150,
    render: (props: any) =>
      renderDescriptionList(props, infoRequests, 'description', 'id'),
  },
  {
    field: 'originalFileName',
    title: 'File Name',
    show: true,
    width: 290,
    render: (props: any) => {
      return (
        <TdNoEllipsis>
          <Button
            type="button"
            boxshadow="none"
            color={Colors.black}
            bordercolor={Colors.white}
            backgroundcolor={Colors.white}
            onClick={() => onClickDownloadButton(props.dataItem.id)}
            leftIcon={
              loadingDownload ? (
                <Flex alignContent="center" pr={1} pb={2}>
                  <CircularProgress width={9} />
                </Flex>
              ) : undefined
            }
          >
            <Flex style={{ gap: 3 }}>
              {props.dataItem[props.field].length > 30
                ? `${props.dataItem[props.field].substring(0, 30)}...`
                : props.dataItem[props.field]}{' '}
              <Icon name="Download" />
            </Flex>
          </Button>
        </TdNoEllipsis>
      )
    },
  },
  {
    field: 'isPublic',
    title: 'Visibility',
    show: isInternal,
    width: 95,
    notFilterable: true,
    render: (props: any) => {
      return (
        <TdNoEllipsis>
          <Button
            type="button"
            boxshadow="none"
            color={Colors.black}
            bordercolor={Colors.white}
            backgroundcolor={Colors.white}
            onClick={() => onClickEdit(props.dataItem.id)}
            style={{ padding: 0 }}
          >
            <Badge
              display="flex"
              backgroundColor={Colors.gray}
              color={Colors.darkGray}
              style={{ gap: 4 }}
            >
              <Icon
                name={
                  props.dataItem[props.field] === true
                    ? 'Visibility'
                    : 'VisibilityOff'
                }
              />
              {props.dataItem[props.field] === true ? 'Public' : 'Private'}
            </Badge>
          </Button>
        </TdNoEllipsis>
      )
    },
  },
]
