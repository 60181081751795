import { fFormatDate, Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementOutOfStatDataParams {
  data: any
}
const PostPlacementOutOfStatData: React.FC<
  PostPlacementOutOfStatDataParams
> = ({ data }) => {
  return (
    <Grid>
      <Typography>
        Out Of Statute Date: {fFormatDate(data?.OutOfStatuteDate)}
      </Typography>
      <Typography>Out Of Statute State: {data?.OutOfStatuteState}</Typography>
    </Grid>
  )
}

export default PostPlacementOutOfStatData
