import {
  fFormatDate,
  fNumberCurrency,
  Grid,
  Typography,
} from 'everchain-uilibrary'
import React from 'react'
import { profileCountry } from 'src/utils/user'

interface PostPlacementPifSifDataParams {
  data: any
}
const PostPlacementPifSifData: React.FC<PostPlacementPifSifDataParams> = ({
  data,
}) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={6}>
        <Typography>Type: {data?.PifSifType}</Typography>
        <Typography>Payment Date: {fFormatDate(data?.PaymentDate)}</Typography>
        <Typography>
          Payment Amount:{' '}
          {fNumberCurrency(
            data?.PaymentAmount ? data?.PaymentAmount.toFixed(2) : '',
            profileCountry()
          )}
        </Typography>
        <Typography>
          Check Or Reference Number: {data?.CheckOrReferenceNumber}
        </Typography>
        <Typography>Location Or Store: {data?.LocationOrStore}</Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Typography>Consumer Address: {data?.ConsumerAddress}</Typography>
        <Typography>Consumer City: {data?.ConsumerCity}</Typography>
        <Typography>Consumer State: {data?.ConsumerState}</Typography>
        <Typography>
          Consumer Postal Code: {data?.ConsumerPostalCode}
        </Typography>
        <Typography>
          Consumer Phone Number: {data?.ConsumerPhoneNumber}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PostPlacementPifSifData
