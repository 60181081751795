import { BACKEND_URL } from 'src/infra/http/httpClient'

const accountsPrefix = 'accounts.'

export const AccountsGetAccounts = `${BACKEND_URL}${accountsPrefix}getAccounts`
export const AccountsGetAccountDetails = `${BACKEND_URL}${accountsPrefix}getAccountDetails`
export const AccountsGetAccountChainOfPlacement = `${BACKEND_URL}${accountsPrefix}getAccountChainOfPlacement`
export const AccountsGetAccountActivity = `${BACKEND_URL}${accountsPrefix}getAccountActivity`
export const AccountsGetAccountsRestingArea = `${BACKEND_URL}${accountsPrefix}getAccountsRestingArea`
export const AccountsGetFileAccountExport = `${BACKEND_URL}${accountsPrefix}getAccountsFile`
export const AccountsGetVendors = `${BACKEND_URL}${accountsPrefix}getVendorsAccount`
