import { ErrorToast, SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { ChargeOffAccountFileData } from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const ChargeOffStrategyAccountFileValidation: React.FC<
  FileValidationParams
> = ({ fileOperations, accountPostOperations }: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  window.localStorage.removeItem('accountsRestingAreaFilterStorage')
  window.localStorage.removeItem('accountsSelected')
  window.localStorage.removeItem('accountsRestingAreaFiltered')

  const mutationChargeOffAccounts = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processChargeOffAccounts(
        params.creditorId,
        params.chargeOffAccountFileData,
        params.file,
        null,
        params.chargeOffOptions
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('creditorId') ||
    !stateData.hasOwnProperty('fileUploaded') ||
    !stateData.hasOwnProperty('actionAfterChargeOff')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    ErrorToast('Error')
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.ChargeOffAccounts)
  const {
    fileData,
    creditorId,
    fileUploaded,
    actionAfterChargeOff,
    strategyId,
  } = stateData

  const mapToAccountFileObject = (input: any): ChargeOffAccountFileData => {
    const dataRow: ChargeOffAccountFileData = {
      rowIndex: input.rowindex,
      ecaid: input.ecaid,
      lender: input.lender,
      loanid: input.loanid,
      chargeOffAccount: input.chargeoffaccount,
      addFeeBalanceToPostChargeOffPrincipal:
        input.addfeebalancetopostchargeoffprincipal,
      zeroOutInterestBalanceAtChargeOff:
        input.zerooutinterestbalanceatchargeoff,
      addInterestBalancetoPostChargeOffPrincipal:
        input.addinterestbalancetopostchargeoffprincipal,
      zeroOutFeeBalanceAtChargeOff: input.zerooutfeebalanceatchargeoff,
    }

    return dataRow
  }

  const handleConfirmProcessAccountFile = () => {
    setIsFetching(true)
    const chargeOffAccountFileData: ChargeOffAccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      chargeOffAccountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const chargeOffOptions = {
      actionAfterChargeOff: actionAfterChargeOff,
      strategyId: strategyId,
    }

    const params = {
      creditorId,
      chargeOffAccountFileData,
      file: fileUploaded,
      chargeOffOptions,
    }

    mutationChargeOffAccounts.mutate(params)
  }

  return (
    <>
      <ValidationResult
        fileMap={fileMap}
        fileOptions={{
          validateTotalBalance: false,
          formatDate: profileCountry(),
          validateTotalAmount: false,
        }}
        fileData={fileData}
        processmentErrors={processmentErrors}
        isFetchingProceed={isFetching}
        onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
        onClickProceedButton={handleConfirmProcessAccountFile}
      />
    </>
  )
}

export default ChargeOffStrategyAccountFileValidation
