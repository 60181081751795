import React from 'react'
import { makeStrategyData } from '../../../../feature/get/Strategy'
import StrategyList from 'src/presentation/pages/Strategies/StrategiesManager/List'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeStrategyPostData } from 'src/main/factories/feature/post/Strategy'

const makeStrategyList: React.FC = () => {
  return (
    <StrategyList
      strategyOperations={makeStrategyData()}
      businessOperations={makeBusinessGetData()}
      strategyPostOperations={makeStrategyPostData()}
    />
  )
}

export default makeStrategyList
