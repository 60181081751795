/* eslint-disable max-len */
import React from 'react'
import { makeAssetTypesData } from 'src/main/factories/feature/get/AssetTypes'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeStrategyData } from 'src/main/factories/feature/get/Strategy'
import { makeStrategyPostData } from 'src/main/factories/feature/post/Strategy'
import makeStrategyValidation from 'src/main/factories/validation/strategy'
import makeStrategyStagesValidation from 'src/main/factories/validation/strategyStages'
import StrategyForm from 'src/presentation/pages/Strategies/StrategiesManager/Form'

const makeStrategyForm: React.FC = () => {
  return (
    <StrategyForm
      strategyOperations={makeStrategyPostData()}
      validation={makeStrategyValidation}
      assetTypeOperations={makeAssetTypesData()}
      businessOperations={makeBusinessGetData()}
      strategyGetOperations={makeStrategyData()}
      strategyStageValidation={makeStrategyStagesValidation}
    />
  )
}

export default makeStrategyForm
