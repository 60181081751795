/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { List, ListItem } from '@material-ui/core'
import {
  Dropdown,
  ModalDialog,
  Step,
  UploadDragDrop,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import { useHistory } from 'react-router-dom'
import { TRANSACTION_FILE_VALIDATION } from 'src/presentation/routes'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { useCustomQuery } from 'src/infra/reactQuery'
import { getStandardUri } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'

interface LoadTransactionModalProps {
  open: boolean
  setOpenLoadModal: any
  businessOperations: IBusinessGetOperations
}

export interface FileParseResult {
  passedValidation: boolean
  errorMessage: string
  data: any[]
}

const LoadTransactionModal: React.FC<LoadTransactionModalProps> = ({
  open,
  setOpenLoadModal,
  businessOperations,
}: LoadTransactionModalProps) => {
  const history = useHistory()
  const [files, setFiles] = useState<File[]>([])
  const [form, setForm] = useState<any>({
    businessId: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const { isVendor, isCreditor } = useContext(AuthContext)

  const { data: creditorList, isLoading: isCreditorLoading } =
    useCustomQuery<any>(
      ['business.getCreditors', isVendor, isCreditor],
      async () => businessOperations.getCreditorsData(),
      { cacheTime: 0 }
    )
  const { data: vendorList, isLoading: isVendorLoading } = useCustomQuery<any>(
    ['business.getVendors', isVendor, isCreditor],
    async () => businessOperations.getVendorsData(),
    { cacheTime: 0 }
  )
  let businessList = isCreditor ? creditorList : vendorList
  useEffect(() => {
    setForm({
      ...form,
      businessId: null,
    })
  }, [isVendor, isCreditor])
  return (
    <>
      <ModalDialog
        isOpen={open}
        buttonOkText="Upload"
        onClose={() => {
          setOpenLoadModal(false)
        }}
        onContinue={() => {
          if (files[0]) {
            setIsLoading(true)
            fParseXlsxFile(files[0]).then((result: any) => {
              history.push({
                pathname: getStandardUri(TRANSACTION_FILE_VALIDATION),
                state: {
                  businessId: form.businessId,
                  fileData: result.data,
                  fileUploaded: files[0],
                },
              })
            })
          }
        }}
        header="Load Balance Adjustment"
        isLoading={isLoading}
        isFetching={isLoading}
        disableOkButton={files[0] === undefined || form.businessId === null}
      >
        <List>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={form.businessId}
              title="Select the business"
              stepNumber="1"
            >
              <Dropdown
                isLoading={isCreditorLoading || isVendorLoading}
                width={'350px'}
                options={businessList}
                placeholder="Business"
                onChange={(props: any) => {
                  setForm({
                    ...form,
                    businessId: props?.id,
                  })
                }}
                value={form.creditorId}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              title="Upload your file"
              stepNumber="2"
              completed={files[0] !== undefined}
            >
              <UploadDragDrop
                files={files}
                setFiles={setFiles}
                hideUploadButton={true}
              />
            </Step>
          </ListItem>
        </List>
      </ModalDialog>
    </>
  )
}

export default LoadTransactionModal
