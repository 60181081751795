/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState } from 'react'
import {
  Accordion,
  Button,
  Colors,
  DataTable,
  DataTableState,
  Dropdown,
  Flex,
  Group,
  Icon,
  ModalDialog,
  Skeleton,
  SuccessToast,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { complaintInfoRequestColumns } from './gridColumns'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { AuthContext } from 'src/context/AuthenticationContext'
import { Autocomplete, TextField } from '@mui/material'

interface ComplaintInfoRequestAccordionProps {
  complaintGetOperations?: IComplaintGetOperations
  complaintPostOperations?: IComplaintPostOperations
  complaintId?: number
  isLoading?: boolean
}

const ComplaintInfoRequestAccordion: React.FC<
  ComplaintInfoRequestAccordionProps
> = ({
  complaintGetOperations,
  complaintPostOperations,
  complaintId,
  isLoading,
}) => {
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [infoId, setInfoId] = useState<number | undefined>(undefined)
  const [hasError, setHasError] = useState<boolean>(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const {
    data: infoRequestData,
    isFetching: loadingInfoRequestData,
    refetch: refetchGetQuery,
  } = useCustomQuery(
    ['getComplaintInfoRequests', gridState],
    async () =>
      complaintGetOperations?.getComplaintInfoRequests(complaintId!, gridState),
    { cacheTime: 0, enabled: !!complaintId }
  )

  const { data: descriptionsData, isFetching: loadingDescriptionsData } =
    useCustomQuery(
      ['getComplaintInfoRequestDescriptions', gridState],
      async () => complaintGetOperations?.getComplaintInfoRequestDescriptions(),
      { cacheTime: 0, enabled: true }
    )

  const { data: statusesData } = useCustomQuery(
    ['getComplaintInfoRequestStatuses', gridState],
    async () => complaintGetOperations?.getComplaintInfoRequestStatuses(),
    { cacheTime: 0, enabled: true }
  )

  const handleOnClickSave = () => {
    if (!description || !complaintId) {
      setHasError(true)
      return
    }

    setHasError(false)
    setLoadingSaveButton(true)

    if (infoId) {
      complaintPostOperations
        ?.updateComplaintInfoRequest({ id: infoId, status, description })
        .then(() => {
          SuccessToast('Complaint info request updated successfully')
          setDescription('')
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
          setInfoId(undefined)
          setOpenEditModal(false)
        })
    } else {
      complaintPostOperations
        ?.saveComplaintInfoRequest({ complaintId, description })
        .then(() => {
          SuccessToast('Complaint info request created successfully')
          setDescription('')
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
        })
    }
  }

  const handleEditButton = (id: number) => {
    const infoRecord = infoRequestData?.data.find((x) => x.id === id)

    setInfoId(id)
    setDescription(infoRecord?.description)
    setStatus(infoRecord?.status)
    setOpenEditModal(true)
  }

  const transformedStatuses =
    statusesData?.map((item) => ({
      value: item,
      label: item,
    })) || []

  return (
    <Accordion
      title="Additional Info Request"
      icon={<Icon name="ContactSupport" />}
    >
      <Group flexDirection="column">
        {isInternal && (
          <>
            <Flex justifyContent="flex-end">
              <Button
                leftIcon={<Icon name="Add" fontSize="small" />}
                type="button"
                onClick={handleOnClickSave}
                isFetching={loadingSaveButton && !openEditModal}
                isLoading={isLoading}
                disabled={hasError && !description}
              >
                Add Additional Info Request
              </Button>
            </Flex>
            <Skeleton
              isLoading={(loadingDescriptionsData || isLoading) ?? false}
            >
              <Autocomplete
                options={descriptionsData ?? []}
                clearOnEscape
                freeSolo
                value={description ?? null}
                onChange={(event: any, newValue: string | null) =>
                  setDescription(newValue || undefined)
                }
                renderInput={(params) => {
                  const inputProps = {
                    ...params.inputProps,
                    value: description ?? '',
                  }

                  return (
                    <TextField
                      {...params}
                      inputProps={inputProps}
                      label="Enter the Info Request description"
                      fullWidth
                      error={hasError && !description}
                      helperText={
                        hasError && !description ? 'Required' : undefined
                      }
                      onChange={(e) => setDescription(e.target.value)}
                      value={description ?? ''}
                      variant="standard"
                    />
                  )
                }}
              />
            </Skeleton>
          </>
        )}
        <DataTable
          isLoading={loadingInfoRequestData || isLoading}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintInfoRequestColumns(
            handleEditButton,
            isInternal
          )}
          gridState={gridState}
          data={infoRequestData?.data ?? []}
          pageable
          useFilterMenu
          total={infoRequestData?.totalCount}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
        />
      </Group>
      <ModalDialog
        header="Edit Info Request"
        isOpen={openEditModal}
        onClose={() => {
          setHasError(false)
          setDescription('')
          setInfoId(undefined)
          setOpenEditModal(false)
        }}
        buttonOkText="Save"
        onContinue={handleOnClickSave}
        isFetching={loadingSaveButton}
        typeOkButton="button"
      >
        <Group color={Colors.primary} flexDirection="column">
          <Dropdown
            width="100%"
            placeholder="Status"
            options={transformedStatuses ?? []}
            value={status}
            onChange={(option) => setStatus(option.value)}
            allowEmptyValue={false}
          />
          <Autocomplete
            options={descriptionsData ?? []}
            clearOnEscape
            freeSolo
            onChange={(event: any, newValue: string | null) =>
              setDescription(newValue || undefined)
            }
            value={description}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter the Info Request description"
                fullWidth
                error={hasError && !description}
                helperText={hasError && !description ? 'Required' : undefined}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '14px !important',
                    transform: 'translate(0, 0.5px) scale(0.821) !important',
                  },
                }}
              />
            )}
          />
        </Group>
      </ModalDialog>
    </Accordion>
  )
}

export default ComplaintInfoRequestAccordion
