/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react'

import {
  Box,
  Form,
  FormDropdown,
  FormInput,
  FormModalSubmitButton,
  useForm,
} from 'everchain-uilibrary'
import { useMessageSnackbar } from 'src/utils/notification'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { Options } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'

import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { StrategyStageDistribution } from 'src/domain/models/strategy'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { Business } from 'src/domain/models/business'
import { getValidation } from 'src/validation/utils'
import makeStrategyDistributionValidation from 'src/main/factories/validation/strategyStagesDistribution'
import { useCustomQuery } from 'src/infra/reactQuery'
interface StrategyVendorParams {
  strategyId: number
  strategyStageId: number
  businessOperations?: IBusinessGetOperations
  onCompleted: () => void
  strategyPostOperations?: IStrategyPostOperations
  strategyGetOperations?: IStrategyGetOperations
  dynamicDistribution: boolean
  strategyStageDistribution?: any
}

const StrategyVendorForm: React.FC<StrategyVendorParams> = ({
  strategyId,
  strategyStageId,
  onCompleted,
  businessOperations,
  strategyPostOperations,
  strategyGetOperations,
  dynamicDistribution,
  strategyStageDistribution,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showSuccessMessage } = useMessageSnackbar()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [vendors, setVendors] = useState<Business[]>()
  const [formStrategy] = useState<StrategyStageDistribution>({
    distribution: strategyStageDistribution?.distribution ?? 0,
    fee: strategyStageDistribution?.fee ?? undefined,
    id: strategyStageDistribution?.id ?? 0,
    vendorId: strategyStageDistribution?.vendorId ?? '',
    strategyStageId: strategyStageId,
    strategyId: strategyId,
  })
  const methods = useForm({ defaultValues: formStrategy })
  const [selectedVendorFee, setSelectedVendorFee] = useState<any>(
    strategyStageDistribution?.fee ?? ''
  )
  const [vendorsDropDownOptions, setVendorsDropDownOptions] = useState<
    Options[]
  >([])

  const getDistributionValidation = () => {
    return makeStrategyDistributionValidation(
      100 - (totalDistribution - orginalDistribution)
    )
  }

  const onSubmitStage = (data: any) => {
    setLoading(true)
    if (data.id > 0) {
      strategyPostOperations
        ?.updateStrategyStageDistribution(data)
        .then((response: any) => {
          showSuccessMessage('Strategy Distribution edited successfully')
          setLoading(false)
          onCompleted()
        })
        .catch((error) => {
          setLoading(false)
        })
    } else {
      strategyPostOperations
        ?.addStrategyStageDistribution(data)
        .then((response: any) => {
          showSuccessMessage('Strategy Distribution created successfully')
          setLoading(false)
          onCompleted()
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }

  const [totalDistribution, setTotalDistribution] = useState<any>()

  const [orginalDistribution] = useState<number>(
    strategyStageDistribution?.distribution ?? 0
  )

  const { isFetching: isVendorsByStrategyAndStageLoading } =
    useCustomQuery<any>(
      ['getVendorsDataByStrategyAndStage', strategyStageId, formStrategy.id],
      async (): Promise<any> =>
        businessOperations
          ?.getVendorsDataByStrategyAndStage(
            strategyId,
            strategyStageId,
            formStrategy.id
          )
          .then((response) => {
            setVendors(response)
            setVendorsDropDownOptions(getDropDownOptions(response || []))
            return response
          }),
      { cacheTime: 0 }
    )

  const { isFetching: isTotalDistributionLoading } = useCustomQuery<any>(
    ['getTotalDistribution', strategyId, strategyStageId],
    async (): Promise<any> =>
      strategyGetOperations
        ?.getTotalDistribution(strategyId, strategyStageId)
        .then((response: any) => {
          setTotalDistribution(response)
          return response
        }),
    { cacheTime: 0 }
  )

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) => {
      const vendorFee = item?.preChargeOffStageAssetTypeFee
        ? item?.preChargeOffStageAssetTypeFee
        : item?.postChargeOffStageAssetTypeFee
      return newOptions.push({
        label: isInternal
          ? `${item.name} (${item.status}) - ${vendorFee}%`
          : `${item.name} - ${vendorFee}%`,
        value: item.id,
      })
    })
    setLoading(false)
    return newOptions
  }

  const handleSelectVendor = (props: any) => {
    if (!props?.value) {
      setSelectedVendorFee('')
    }

    const selectedVendor = vendors?.find((x) => x.id === props?.value)
    const fee = selectedVendor?.preChargeOffStageAssetTypeFee
      ? selectedVendor?.preChargeOffStageAssetTypeFee
      : selectedVendor?.postChargeOffStageAssetTypeFee
    setSelectedVendorFee(fee)
    methods.setValue('vendorId', props?.value)
    methods.setValue('fee', fee)
  }

  const handleSelectDistribution = (props: any) => {
    if (props?.target?.valueAsNumber) {
      methods.setValue('distribution', props?.target?.valueAsNumber, {
        shouldValidate: true,
      })
    } else {
      methods.setValue('distribution', 0, {
        shouldValidate: true,
      })
    }
  }

  return (
    <Form
      initialValues={formStrategy}
      onSubmit={onSubmitStage}
      methods={methods}
      isFetching={
        loading ||
        isTotalDistributionLoading ||
        isVendorsByStrategyAndStageLoading
      }
      isLoading={
        loading ||
        isTotalDistributionLoading ||
        isVendorsByStrategyAndStageLoading
      }
    >
      <Box>
        <Box>
          <FormDropdown
            id="vendor-dropdown"
            validation={getValidation(getDistributionValidation(), 'vendorId')}
            name="vendorId"
            placeholder="Vendor"
            width="20rem"
            options={vendorsDropDownOptions}
            onChange={handleSelectVendor}
            isFetching={isVendorsByStrategyAndStageLoading || loading}
            isLoading={isVendorsByStrategyAndStageLoading || loading}
            disabled={isVendorsByStrategyAndStageLoading}
          />
        </Box>
        {!dynamicDistribution && (
          <Box mt={3}>
            <FormInput
              id="distribution-input"
              validation={getValidation(
                getDistributionValidation(),
                'distribution'
              )}
              isLoading={isTotalDistributionLoading}
              width="20rem"
              placeholder="Distribution"
              name="distribution"
              disabled={isTotalDistributionLoading}
              type="number"
              afterValue="%"
              onChange={handleSelectDistribution}
            />
          </Box>
        )}
        {selectedVendorFee && (
          <Box mt={3}>
            <FormInput
              id="fee-input"
              width="20rem"
              placeholder="Fee"
              name="fee"
              isLoading={loading}
              disabled={loading}
              type="number"
              afterValue="%"
              readOnly={true}
              value={selectedVendorFee}
            />
          </Box>
        )}
      </Box>
      <FormModalSubmitButton text="Save" disabled={loading || false} />
    </Form>
  )
}

export default StrategyVendorForm
