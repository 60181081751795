import React from 'react'
import { makeAccountsData } from 'src/main/factories/feature/get/Accounts'
import { makeClientGetData } from 'src/main/factories/feature/get/clients'
import { makeComplaintGetData } from 'src/main/factories/feature/get/Complaints'
import { makeComplaintPostData } from 'src/main/factories/feature/post/Complaint'
import ComplaintDetail from 'src/presentation/pages/Complaint/Detail'

const makeComplaintDetail: React.FC = () => {
  return (
    <ComplaintDetail
      complaintGetOperations={makeComplaintGetData()}
      complaintPostOperations={makeComplaintPostData()}
      clientGetOperations={makeClientGetData()}
      accountGetOperations={makeAccountsData()}
    />
  )
}

export default makeComplaintDetail
