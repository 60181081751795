/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import {
  Accordion,
  Box,
  Colors,
  FormDropdown,
  FormInput,
  FormSwitch,
  Grid,
  Icon,
  maskPhoneNumber,
  ValidationRulesDictionary,
} from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { getValidation } from 'src/validation/utils'

interface ComplaintInfoAccordionProps {
  complaintGetOperations?: IComplaintGetOperations
  isLoading?: boolean
  complaintValidation?: ValidationRulesDictionary
  formMethods?: any
}

const ComplaintInfoAccordion: React.FC<ComplaintInfoAccordionProps> = ({
  complaintGetOperations,
  isLoading,
  complaintValidation,
  formMethods,
}) => {
  const { data: sourceTypesData, isLoading: loadingSourceTypesData } =
    useCustomQuery(
      ['getComplaintSourceTypes'],
      async () => complaintGetOperations?.getComplaintSourceTypes(),
      { cacheTime: 0, enabled: true }
    )

  const onChangeComplainantPhone = (event: any) => {
    if (event.target.value) {
      formMethods?.setValue(
        'complainantPhone',
        maskPhoneNumber(event.target.value)
      )
    }
  }

  return (
    <Accordion title="Complaint Info" icon={<Icon name="Info" />} defaultOpen>
      <Grid container spacing={4} style={{ color: Colors.black }}>
        <Grid item xs={12}>
          <FormInput
            name="summary"
            placeholder="Summary"
            width="100%"
            isLoading={isLoading}
            validation={getValidation(complaintValidation, 'summary')}
          />
        </Grid>
        <Grid item xs={7} md={7}>
          <FormDropdown
            name="sourceType"
            placeholder="Source Type"
            options={sourceTypesData ?? []}
            width="100%"
            isLoading={loadingSourceTypesData || isLoading}
          />
        </Grid>
        <Grid item xs={5} md={5}>
          <Box pl={1} pt={3} pb={3}>
            <FormSwitch
              name="attorneyContacted"
              label="Attorney Contacted"
              isLoading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            name="complainantEmail"
            placeholder="Consumer Email"
            width="100%"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            name="complainantPhone"
            placeholder="Consumer Phone"
            width="100%"
            isLoading={isLoading}
            maxLength={20}
            onChange={onChangeComplainantPhone}
            showMaxInfo={false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            name="complainantName"
            placeholder="Consumer Name"
            width="100%"
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}

export default ComplaintInfoAccordion
