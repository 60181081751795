export class ValidationRules {
  rules: Record<string, any> = {}

  required(message: string) {
    this.rules.required = { value: true, message }
    return this
  }

  minLength(value: number, message: string) {
    this.rules.minLength = { value, message }
    return this
  }

  maxLength(value: number, message: string) {
    this.rules.maxLength = { value, message }
    return this
  }

  max(value: number, message: string) {
    this.rules.max = { value, message }
    return this
  }
  min(value: number, message: string) {
    this.rules.min = { value, message }
    return this
  }

  pattern(pattern: RegExp, message: string) {
    this.rules.pattern = { value: pattern, message }
    return this
  }

  string(message: string) {
    const validateFn = (value: any) => typeof value === 'string' || message
    this.rules.validate = { value: validateFn }
    return this
  }

  boolean(message: string) {
    const validateFn = (value: any) => typeof value === 'boolean' || message
    this.rules.validate = { value: validateFn }
    return this
  }

  number(message: string) {
    const validateFn = (value: any) => typeof value === 'number' || message
    this.rules.validate = { value: validateFn }
    return this
  }

  functionValidation(
    validateFn?: (value: any, context?: Record<string, any>) => boolean | string
  ) {
    if (validateFn) {
      this.rules.validate = validateFn
    }
    return this
  }

  get() {
    return this.rules
  }
}
export interface ValidationRulesDictionary {
  [key: string]: ValidationRules
}
