import { fNumberCurrency, Grid, Typography } from 'everchain-uilibrary'
import React from 'react'
import { profileCountry } from 'src/utils/user'

interface PostPlacementLowBalanceDataParams {
  data: any
}
const PostPlacementLowBalanceData: React.FC<
  PostPlacementLowBalanceDataParams
> = ({ data }) => {
  return (
    <Grid>
      <Typography>
        Total Balance At Closure:{' '}
        {fNumberCurrency(
          data?.TotalBalanceAtClosure
            ? data?.TotalBalanceAtClosure.toFixed(2)
            : '',
          profileCountry()
        )}
      </Typography>
    </Grid>
  )
}

export default PostPlacementLowBalanceData
