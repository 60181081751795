import { BACKEND_URL } from 'src/infra/http/httpClient'

const complaintsPrefix = 'complaints.'

export const ComplaintsGetComplaints = `${BACKEND_URL}${complaintsPrefix}getComplaints`
export const ComplaintsGetPreview = `${BACKEND_URL}${complaintsPrefix}getPreview`
export const ComplaintsGetById = `${BACKEND_URL}${complaintsPrefix}getComplaintById`
export const ComplaintsGetStatuses = `${BACKEND_URL}${complaintsPrefix}getComplaintStatuses`
export const ComplaintsGetViolationTypes = `${BACKEND_URL}${complaintsPrefix}getComplaintViolationTypes`
export const ComplaintsGetOutcomes = `${BACKEND_URL}${complaintsPrefix}getComplaintOutcomes`
export const ComplaintsGetAccounts = `${BACKEND_URL}${complaintsPrefix}getComplaintAccounts`
export const ComplaintsGetSourceTypes = `${BACKEND_URL}${complaintsPrefix}getComplaintSourceTypes`
export const ComplaintsGetAccountStatuses = `${BACKEND_URL}${complaintsPrefix}getComplaintAccountStatuses`
export const ComplaintsGetCards = `${BACKEND_URL}${complaintsPrefix}getComplaintCards`
export const ComplaintsGetComments = `${BACKEND_URL}${complaintsPrefix}getComplaintComments`
export const ComplaintsGetInfoRequests = `${BACKEND_URL}${complaintsPrefix}getComplaintInfoRequests`
export const ComplaintsGetInfoRequestDescriptions = `${BACKEND_URL}${complaintsPrefix}getComplaintInfoRequestDescriptions`
export const ComplaintsGetInfoRequestStatuses = `${BACKEND_URL}${complaintsPrefix}getComplaintInfoRequestStatuses`
export const ComplaintsGetAttachments = `${BACKEND_URL}${complaintsPrefix}getComplaintAttachments`
export const ComplaintsGetUriAttachment = `${BACKEND_URL}${complaintsPrefix}getUriComplaintAttachment`
