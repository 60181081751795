/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import {
  fFormatDate,
  fNumberCurrencyAbbreviated,
  Paper,
  Summary,
} from 'everchain-uilibrary'
import { ComplaintDto } from 'src/domain/models/complaint'
import { profileCountry } from 'src/utils/user'

interface ComplaintSummaryProps {
  complaintDto?: ComplaintDto
  isLoading?: boolean
}

const ComplaintSummary: React.FC<ComplaintSummaryProps> = ({
  complaintDto,
  isLoading,
}) => {
  const gridItemValues = [
    { header: 'Loan Id', value: complaintDto?.lenderLoanId, order: 1 },
    {
      header: 'Original Loan Amount',
      value: complaintDto?.originalLoanAmount
        ? fNumberCurrencyAbbreviated(
            complaintDto?.originalLoanAmount,
            profileCountry()
          )
        : undefined,
      order: 2,
    },
    { header: 'Placer', value: complaintDto?.placerName, order: 3 },
    { header: 'Lender', value: complaintDto?.lender, order: 4 },
    { header: 'Vendor', value: complaintDto?.vendorName, order: 5 },
    {
      header: 'Original Date',
      value: complaintDto?.originalDate
        ? fFormatDate(complaintDto?.originalDate)
        : undefined,
      order: 6,
    },
    {
      header: 'Balance',
      value: complaintDto?.totalBalance
        ? fNumberCurrencyAbbreviated(
            complaintDto?.totalBalance,
            profileCountry()
          )
        : undefined,
      order: 7,
    },
    {
      header: 'Full Name',
      value: `${complaintDto?.firstName} ${complaintDto?.lastName}`,
      order: 8,
    },
  ]

  const summaryDetails = {
    gridItemValues,
  }

  const summaryData = {
    summaryDetails: [summaryDetails],
  }

  return (
    <Paper>
      <Summary
        summaryData={summaryData}
        isLoading={isLoading}
        skeletonWidth="100%"
        numberOfColumns={4}
      />
    </Paper>
  )
}

export default ComplaintSummary
