/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import {
  DeleteStrategyStages,
  Strategy,
  StrategyStage,
} from 'src/domain/models/strategy'
import {
  Form,
  FormInput,
  FormDropdown,
  Box,
  FormCheckBox,
  FormSubmitButton,
  Button,
  renderExpanded,
  DataTable,
  renderDescriptionList,
  renderPercent,
  renderWithMessageEnd,
  DataTableState,
  DataTableDetailRowProps,
  ModalDialog,
  Grid,
  Colors,
  Typography,
  renderCellTableActions,
  headerActions,
  Header,
  Content,
  Group,
  useForm,
  renderElementWithTooltip,
  Tooltip,
  IconButton,
  ValidationRulesDictionary,
} from 'everchain-uilibrary'
import { getValidation } from 'src/validation/utils'
import { useMessageSnackbar } from 'src/utils/notification'
import Page from '../../../Common/Page'
import { STRATEGIES, STRATEGY } from 'src/presentation/routes'
import { IAssetTypesGetOperations } from 'src/domain/features/get/assetTypes/assetTypes'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { AuthContext } from 'src/context/AuthenticationContext'

import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { Paper } from '@material-ui/core'
import { Options, getStandardUri } from 'src/utils/common'
import StrategyStageForm from './StrategyStageForm'
import { ChargeOffTypes, PermissionCodeAccess } from 'src/utils/constants'
import StrategyVendorForm from './StrategyVendorForm'
import { AbilityContext } from 'src/context/Can'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

interface StrategyParams {
  strategyOperations?: IStrategyPostOperations
  validation?: ValidationRulesDictionary | undefined
  assetTypeOperations?: IAssetTypesGetOperations
  businessOperations?: IBusinessGetOperations
  strategyGetOperations?: IStrategyGetOperations
  strategyStageValidation?: ValidationRulesDictionary | undefined
}

const StrategyForm: React.FC<StrategyParams> = ({
  strategyOperations,
  validation,
  assetTypeOperations,
  businessOperations,
  strategyGetOperations,
  strategyStageValidation,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [vendorModalOpen, setVendorModalOpen] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [stageSelected, setStageSelected] = useState<string>('')
  const { id } = useParams<any>()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [assetTypes, setAssetTypes] = useState<any>()
  const { userPermissions, profileBusiness } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [creditors, setCreditors] = useState<any>()
  const [vendors, setVendors] = useState<any>()
  const [strategyStages, setStrategyStages] = useState<StrategyStage[]>()
  const [form, setForm] = useState<Strategy>({
    name: '',
    assetTypeId: '',
    autoRecall: true,
    enabled: false,
    creditorId: '',
    dynamicDistribution: true,
    hasBeenUsed: false,
    id: 0,
    createdBy: '',
    skipPreAssignment: false,
    chargeOffType: '',
  })
  const ability = useContext(AbilityContext)
  const methods = useForm({ defaultValues: form })

  const canEdit =
    ability.can(PermissionCodeAccess.Recover_UpdateStrategies, 'any') ||
    !methods.getValues('id')
  const [isEditingStage, setIsEditingStage] = useState(false)
  const [isEditingVendor, setIsEditingVendor] = useState(false)
  const [strategyStageDistribution, setStrategyStageDistribution] =
    useState<any>()
  const [actionStageId, setActionStageId] = useState<any>()
  const [actionDistributionId, setActionDistributionId] = useState<any>()
  const [isDeleteStrategyStageOpen, setDeleteStrategyStageOpen] =
    useState<boolean>(false)
  const [isDeleteStrategyDistribution, setStrategyDistribution] =
    useState<boolean>(false)
  const { showSuccessMessage } = useMessageSnackbar()

  const dataColumnsDetail = [
    {
      title: 'Vendor',
      field: 'vendorId',
      show: true,
      width: '40px',
      render: (props: any) =>
        renderDescriptionList(props, vendors, 'name', 'id'),
    },
    {
      title: 'Fee',
      field: 'fee',
      show: true,
      width: '40px',
      render: renderPercent,
    },
    {
      title: 'Allocation',
      field: 'distribution',
      show: true,
      width: '40px',
      render: renderPercent,
    },
    {
      title: 'Actions',
      render: (props: any) =>
        renderCellTableActions(
          props,
          actionsMenuVendor(props).filter((x) => x.show === true)
        ),
      headerCell: headerActions,
      show:
        !methods.getValues('hasBeenUsed') &&
        (isInternal ||
          profileBusiness.find(
            (x) => x.Id.toString() === methods.getValues('creditorId')
          ) !== undefined),
      width: '10px',
    },
  ]
  const StageDetails = (props: DataTableDetailRowProps) => {
    const { dataItem } = props
    return <DataTable data={dataItem.details} gridColumns={dataColumnsDetail} />
  }

  const expandRowChange = (dataItem: StrategyStage) => {
    const newItem: StrategyStage = {
      id: dataItem.id,
      description: dataItem.description,
      duration: dataItem.duration,
      order: dataItem.order,
      strategyId: dataItem.strategyId,
      expanded: !dataItem.expanded,
      details: dataItem.details,
    }
    if (strategyStages) {
      const indexToRemove = strategyStages.findIndex(
        (f) => f.id === dataItem.id
      )

      const oldData = strategyStages.filter((f) => f.id !== dataItem.id)
      oldData.splice(indexToRemove, 0, newItem)
      const newData = oldData

      setStrategyStages(newData)
    }
  }

  const renderIconButton = (props: any) => {
    return (
      props.dataItem['order'] !== 5 &&
      props.dataItem['order'] !== 0 &&
      props.dataItem['order'] !== 6 && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={'Add New Vendor'}>
            <div>
              <IconButton
                onClick={() => handleAddNewVendor(props.dataItem['id'])}
              >
                <PersonAddIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      )
    )
  }

  const handleAddNewVendor = (stageId: any) => {
    setStageSelected(stageId)
    setVendorModalOpen(true)
    setIsEditingVendor(false)
    setStrategyStageDistribution(null)
    setActionDistributionId(null)
  }

  const history = useHistory()
  const GridColumns = () => {
    const columns: any[] = [
      {
        title: ' ',
        field: 'expanded',
        show: true,
        render: (props: any) => renderExpanded(props, expandRowChange),
        width: '10px',
      },
      {
        field: 'description',
        title: 'Description',
        show: true,
        width: '200px',
      },
      {
        field: 'duration',
        title: 'Duration',
        show: true,
        render: (props: any) => renderWithMessageEnd(props, 'days'),
        width: '200px',
      },
      {
        field: '',
        title: '',
        render: (props: any) =>
          renderElementWithTooltip(renderIconButton(props)),
        width: '25px',
        show: true,
      },
      {
        title: 'Actions',
        render: (props: any) =>
          renderCellTableActions(
            props,
            actionsMenu(props).filter((x) => x.show === true)
          ),
        headerCell: headerActions,
        show:
          !methods.getValues('hasBeenUsed') &&
          (isInternal ||
            profileBusiness.find(
              (x) => x.Id.toString() === methods.getValues('creditorId')
            )),
        width: '60px',
      },
    ]

    return columns
  }

  const AllowDelete = (currentStage: number) => {
    const items = getStrategyStagesCreated()
    const biggestOrder = items.reduce((accumulator, currentValue) => {
      return Math.max(accumulator, currentValue)
    })

    return biggestOrder === currentStage
  }
  const actionsMenu = (props: any) => [
    {
      name: 'Edit',
      onClick: (tableCell: any) => {
        setIsEditingStage(true)
        setActionStageId(props.dataItem['id'])
        setOpenModal(true)
      },
      loading: false,
      show: props.dataItem['order'] !== 5,
    },
    {
      name: 'Delete',
      onClick: (tableCell: any) => {
        setActionStageId(props.dataItem['id'])
        setDeleteStrategyStageOpen(true)
      },
      loading: false,
      show: AllowDelete(Number(props.dataItem['order'])),
    },
  ]
  const actionsMenuVendor = (props: any) => [
    {
      name: 'Edit',
      onClick: (tableCell: any) => {
        setIsEditingVendor(true)
        setStrategyStageDistribution(props.dataItem)
        setActionDistributionId(props.dataItem['id'])
        setActionStageId(props.dataItem['strategyStageId'])
        setVendorModalOpen(true)
      },
      loading: false,
      show: !methods.getValues('hasBeenUsed') && props.dataItem['order'] !== 5,
    },
    {
      name: 'Delete',
      onClick: (tableCell: any) => {
        setActionDistributionId(props.dataItem['id'])
        setStrategyDistribution(true)
      },
      loading: false,
    },
  ]
  const onStageCompleted = () => {
    fetchStrategyStages(gridState, id)
    setOpenModal(false)
  }

  const onVendorCompleted = () => {
    fetchStrategyStages(gridState, id)
    setVendorModalOpen(false)
  }
  const onSubmit = (data: any) => {
    setLoading(true)

    if (isEditing) {
      strategyOperations
        ?.editStrategy(data)
        .then((response: any) => {
          fetchStrategy()
          if (!dynamicDistribution && data.dynamicDistribution) {
            fetchStrategyStages(gridState, id)
          }
          showSuccessMessage('Strategy updated successfully')
        })
        .catch((error) => {
          setLoading(false)
        })
    } else {
      strategyOperations
        ?.addStrategy(data)
        .then((response: any) => {
          showSuccessMessage('Strategy created successfully')
          setLoading(false)
          history.push(getStandardUri(`${STRATEGY}/${response.data}`))
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }
  const GetCreditors = async () => {
    setLoading(true)
    try {
      setCreditors(await businessOperations?.getCreditorsData())
      if (id) setVendors(await businessOperations?.getVendorsDataByStrategy(id))

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const isEditing = id
  const fetchStrategyStages = async (
    gridParams: DataTableState,
    strategyId: number
  ) => {
    setLoading(true)
    try {
      const dataResponse =
        await strategyGetOperations?.getStrategyStageByStrategyId(
          gridParams,
          strategyId
        )
      dataResponse?.data.forEach((strategyStage) => {
        strategyStage.expanded =
          strategyStage?.details?.length && strategyStage?.details?.length > 0
            ? true
            : false
      })
      setStrategyStages(
        dataResponse?.data
          ? dataResponse?.data.sort((a, b) => {
              if (Number(a.order) > Number(b.order)) return 1
              else return -1
            })
          : []
      )

      setTotal(dataResponse?.totalCount ?? 0)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [dynamicDistribution, setDynamicDistribution] = useState<boolean>()

  const fetchStrategy = () => {
    setLoading(true)
    strategyGetOperations
      ?.getStrategyById(id)
      .then((data: Strategy) => {
        setDynamicDistribution(data.dynamicDistribution)
        setForm(data)
        methods.setValue('autoRecall', data.autoRecall)
        methods.setValue('createdBy', data.createdBy)
        methods.setValue('hasBeenUsed', data.hasBeenUsed)
        methods.setValue('dynamicDistribution', data.dynamicDistribution)
        methods.setValue('enabled', data.enabled)
        methods.setValue('name', data.name)
        methods.setValue('id', data.id)
        methods.setValue('creditorId', data.creditorId)
        methods.setValue('skipPreAssignment', data.skipPreAssignment)
        methods.setValue('chargeOffType', data.chargeOffType)

        fetchAssetTypes(data.assetTypeId)
      })
      .catch((error) => {
        setLoading(false)
      })
  }
  const getStrategyStagesCreated = () => {
    let stagesCreated: number[] = []
    if (strategyStages) {
      stagesCreated = strategyStages
        .filter((x) => x.order !== null)
        .map((stage) => {
          return Number(stage.order)
        })
    }

    if (form.skipPreAssignment && !stagesCreated.find((x) => x === 0)) {
      stagesCreated.push(0)
      return stagesCreated
    }

    return stagesCreated
  }

  const getCreditorDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.forEach((item: any) =>
      newOptions.push({
        label: isInternal ? `${item.name} (${item.status})` : item.name,
        value: item.id,
      })
    )
    return newOptions
  }

  const optionsCreditor = getCreditorDropDownOptions(creditors || [])

  const handleCreditorSelect = (creditorId: any) => {
    setLoading(true)
    if (creditorId) {
      methods.setValue('creditorId', creditorId)
      methods.setValue('assetTypeId', '')
      setAssetTypes([])
      fetchAssetTypes()
    } else {
      methods.setValue('creditorId', '')
      methods.setValue('assetTypeId', '')
      setAssetTypes([])
      fetchAssetTypes()
    }
  }

  const fetchAssetTypes = (assetTypeId?: string) => {
    assetTypeOperations
      ?.getAssetTypeData(methods.getValues('creditorId'))
      .then((data: any) => {
        setAssetTypes(data)
        if (assetTypeId) {
          methods.setValue('assetTypeId', assetTypeId)
        }
        setLoading(false)
      })
      .catch((error: any) => {
        setLoading(false)
      })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (id) {
      fetchStrategy()
      fetchStrategyStages(gridState, id)
    }
    GetCreditors()
  }, [id])

  if (loading || (id && !vendors))
    return (
      <>
        <Content>
          <div style={{ marginBottom: '15px' }}>
            <Grid item xs={12}>
              <Header title="Editing Strategy" subtitle="Strategy details" />

              <Paper style={{ padding: 15 }} id="strategy-details">
                <Grid>
                  <Grid container>
                    <Grid item md={6}>
                      <Skeleton width={'60%'} height={60} />
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Skeleton width={'40%'} height={30} />
                  </Grid>
                  <Grid item md={6}>
                    <Skeleton width={50} height={50} />
                  </Grid>
                </Grid>
                {isEditing && <Skeleton width="100%" height={400} />}
              </Paper>
            </Grid>
          </div>
        </Content>
      </>
    )
  return (
    <Page
      subtitle="Strategy details"
      title={
        isEditing
          ? 'Editing Strategy: ' + methods.getValues('name')
          : 'Create Strategy'
      }
      customBackUrl={getStandardUri(STRATEGIES)}
    >
      <Paper style={{ padding: 15 }} id="strategy-details">
        <Box display="flex">
          <Form initialValues={form} onSubmit={onSubmit} methods={methods}>
            <Group>
              <FormInput
                validation={getValidation(validation, 'name')}
                placeholder="Name"
                name="name"
                disabled={!canEdit}
              />
              <FormDropdown
                validation={getValidation(validation, 'creditorId')}
                name="creditorId"
                placeholder="Creditor"
                width="380px"
                options={optionsCreditor}
                disabled={!canEdit}
                onChange={(props: any) => handleCreditorSelect(props?.value)}
              />
              <FormDropdown
                validation={getValidation(validation, 'chargeOffType')}
                name="chargeOffType"
                placeholder="Strategy Charge-Off Type"
                width="250px"
                options={ChargeOffTypes}
                valueOptionName="value"
                labelOptionName="key"
              />
              <FormDropdown
                validation={getValidation(validation, 'assetTypeId')}
                name="assetTypeId"
                placeholder="Asset Type"
                width="250px"
                options={assetTypes?.sort((a: any, b: any) =>
                  a.name.localeCompare(b.name)
                )}
                valueOptionName="id"
                labelOptionName="name"
                disabled={!canEdit || methods.getValues('creditorId') === ''}
              />
            </Group>
            <Group pt="5px" pb="5px">
              <Tooltip title="This strategy is enabled and available for use ">
                <div>
                  <FormCheckBox
                    label="Enabled"
                    name="enabled"
                    disabled={!canEdit}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Accounts will be automatically distributed equally among the agencies assigned to each stage.">
                <div>
                  <FormCheckBox
                    label="Dynamic Distribution"
                    name="dynamicDistribution"
                    disabled={methods.getValues('hasBeenUsed') || !canEdit}
                  />
                </div>
              </Tooltip>
              <Tooltip title="When configuring stages, the initial stage will be designated as 'Primary' rather than 'Pre-assignment">
                <div>
                  <FormCheckBox
                    label="Skip Pre-Assignment"
                    name="skipPreAssignment"
                    disabled={
                      methods.getValues('hasBeenUsed') ||
                      !canEdit ||
                      (strategyStages && strategyStages?.length > 0)
                    }
                  />
                </div>
              </Tooltip>
            </Group>
            <Box marginTop="10px">
              <FormSubmitButton
                disabled={loading || !canEdit}
                text={isEditing ? 'Save' : 'Create'}
              />
            </Box>
          </Form>
        </Box>
      </Paper>
      {isEditing && (
        <>
          {!loading ? (
            <div style={{ marginTop: '10px', overflow: 'hidden' }}>
              <Typography variant="subtitle1">Stages</Typography>
              <Group justifyContent="flex-end">
                <Button
                  data-test-id="button-filter"
                  style={{ marginBottom: '5px' }}
                  onClick={() => {
                    setIsEditingStage(false)
                    setActionStageId(undefined)
                    setOpenModal(true)
                  }}
                  variant="primary"
                  disabled={getStrategyStagesCreated().includes(6) || loading}
                  bold
                >
                  Add New Stage
                </Button>
              </Group>
              <DataTable
                expandField="expanded"
                height="100%"
                maxHeight="100%"
                detail={StageDetails}
                gridColumns={GridColumns()}
                gridState={gridState}
                data={strategyStages || []}
                pageable={true}
                total={total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  if (strategyStages && id) {
                    fetchStrategyStages(e.dataState, id)
                  }
                }}
              />
            </div>
          ) : (
            <Skeleton width="100%" height={400} />
          )}
        </>
      )}

      <ModalDialog
        data-test-id="new-stage-dialog"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        header={isEditingStage ? 'Edit Stage' : 'New Stage'}
        isForm={true}
      >
        <StrategyStageForm
          onCompleted={onStageCompleted}
          ordersCreated={getStrategyStagesCreated().sort(function (a, b) {
            return a - b
          })}
          id={id}
          strategyStageValidation={strategyStageValidation}
          strategyStagesPostOperations={strategyOperations}
          isEditing={isEditingStage}
          editStrategyData={strategyStages?.find((x) => x.id === actionStageId)}
        />
      </ModalDialog>
      <ModalDialog
        data-test-id="new-vendor-dialog"
        isOpen={vendorModalOpen}
        onClose={() => setVendorModalOpen(false)}
        header={isEditingVendor ? 'Editing Vendor' : 'New Vendor'}
        isForm={true}
      >
        <StrategyVendorForm
          onCompleted={onVendorCompleted}
          strategyId={id}
          strategyStageId={
            stageSelected ? Number(stageSelected) : actionStageId
          }
          businessOperations={businessOperations}
          strategyPostOperations={strategyOperations}
          dynamicDistribution={methods.getValues('dynamicDistribution')}
          strategyGetOperations={strategyGetOperations}
          strategyStageDistribution={strategyStageDistribution}
        />
      </ModalDialog>
      <ModalDialog
        header="Delete Strategy Stage"
        isOpen={isDeleteStrategyStageOpen}
        buttonCancelText="Cancel"
        buttonOkText="Delete"
        onClose={() => setDeleteStrategyStageOpen(false)}
        onContinue={() => {
          setLoading(true)
          setDeleteStrategyStageOpen(false)
          const params: DeleteStrategyStages = {
            id: actionStageId,
          }
          strategyOperations
            ?.deleteStrategyStages(params)
            .then((response: any) => {
              fetchStrategyStages(gridState, id)
              showSuccessMessage('Strategy stage deleted successfully')
            })
        }}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
        >
          Are you sure that you want to delete this strategy stage? This action
          can't be undone.
        </Typography>
      </ModalDialog>
      <ModalDialog
        header="Delete Vendor"
        isOpen={isDeleteStrategyDistribution}
        buttonCancelText="Cancel"
        buttonOkText="Delete"
        onClose={() => setStrategyDistribution(false)}
        onContinue={() => {
          setLoading(true)
          setStrategyDistribution(false)
          const params: DeleteStrategyStages = {
            id: actionDistributionId,
          }
          strategyOperations
            ?.deleteStrategyDistribution(params)
            .then((response: any) => {
              fetchStrategyStages(gridState, id)
              showSuccessMessage('Strategy distribution deleted successfully')
            })
        }}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
        >
          Are you sure that you want to delete this vendor? This action can't be
          undone.
        </Typography>
      </ModalDialog>
    </Page>
  )
}

export default StrategyForm
