import React, { useContext } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import {
  Box,
  Card,
  Content,
  fFormatDate,
  fNumberCurrency,
  Grid,
  Header,
  Summary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { profileCountry } from 'src/utils/user'
import { PostPlacementDetailsData } from 'src/domain/models/post-placement'
import { useParams } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri } from 'src/utils/common'
import { Display } from '../../Accounts/AccountDetails/styles'
import PostPlacementSpecificData from './components/SpecificData'
import PostPlacementComment from './components/Comments'

interface PostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations: IPostPlacementGetOperations
  postPlacementPostOperations: IPostPlacementPostOperations
}

const PostPlacementDetail: React.FC<PostPlacementParams> = ({
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  const { requestId, backUrl } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const { data: postPlacementData, isLoading: isLoadingPostPlacement } =
    useCustomQuery<PostPlacementDetailsData>(
      ['getPostPlacementDetails'],
      async () => postPlacementOperations?.getPostPlacementDetails(requestId),
      { cacheTime: 0 }
    )

  const createCardTitle = () => {
    if (!postPlacementData) return ''

    if (isInternal) return postPlacementData?.lenderLoanId

    const consumerName = getConsumerName()

    return `${consumerName} | ${postPlacementData?.lenderLoanId}`
  }

  const getConsumerName = () => {
    return `${
      postPlacementData?.lastName
    }, ${postPlacementData?.firstName?.charAt(0)}`
  }

  const summaryData = {
    summaryDetails: [
      {
        blockValues: [
          {
            header: 'Loan ID',
            order: 0,
            value: postPlacementData?.lenderLoanId,
          },
          {
            header: 'Origination Date',
            order: 0,
            value: postPlacementData?.originationDate
              ? fFormatDate(postPlacementData?.originationDate)
              : undefined,
          },
          {
            header: 'Charge Off Balance',
            order: 0,
            value: fNumberCurrency(
              postPlacementData?.chargeOffBalance
                ? postPlacementData?.chargeOffBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
        ],
        gridItemValues: [
          {
            header: 'Consumer Name',
            order: 1,
            value: getConsumerName(),
          },
          {
            header: 'Lender Name',
            order: 2,
            value: postPlacementData?.lender,
          },
          {
            header: 'Portfolio Number',
            order: 3,
            value: 'N/A',
          },
          {
            header: 'Strategy ID',
            order: 4,
            value: postPlacementData?.strategyId,
          },
          {
            header: 'Placer Name',
            order: 5,
            value: postPlacementData?.creditor,
          },
          {
            header: 'Agency Name',
            order: 6,
            value: postPlacementData?.agency,
          },
          {
            header: 'Original Loan Amount',
            order: 7,
            value: fNumberCurrency(
              postPlacementData?.originalLoanAmount
                ? postPlacementData?.originalLoanAmount.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'First Delinquency Date',
            order: 8,
            value: postPlacementData?.firstDelinquencyDate
              ? fFormatDate(postPlacementData?.firstDelinquencyDate)
              : undefined,
          },
          {
            header: 'Write Off Date',
            order: 9,
            value: postPlacementData?.writeOffDate
              ? fFormatDate(postPlacementData?.writeOffDate)
              : undefined,
          },
          {
            header: 'Balance At Start Of Stage',
            order: 10,
            value: fNumberCurrency(
              postPlacementData?.originalTotalBalance
                ? postPlacementData?.originalTotalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Total Payments',
            order: 11,
            value: fNumberCurrency(
              postPlacementData?.totalPayments
                ? postPlacementData?.totalPayments.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Last Payment Date',
            order: 12,
            value: postPlacementData?.lastPaymentDate
              ? fFormatDate(postPlacementData?.lastPaymentDate)
              : undefined,
          },
          {
            header: 'Last Payment Amount',
            order: 13,
            value: fNumberCurrency(
              postPlacementData?.lastPaymentAmount
                ? postPlacementData?.lastPaymentAmount.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Face Value',
            order: 14,
            value: fNumberCurrency(
              postPlacementData?.principalBalance
                ? postPlacementData?.principalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Principal Balance',
            order: 15,
            value: fNumberCurrency(
              postPlacementData?.principalBalance
                ? postPlacementData?.principalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Interest Balance',
            order: 16,
            value: fNumberCurrency(
              postPlacementData?.interestBalance
                ? postPlacementData?.interestBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Fee Balance',
            order: 17,
            value: fNumberCurrency(
              postPlacementData?.currentFeeBalance
                ? postPlacementData?.currentFeeBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
        ],
      },
    ],
  }

  return (
    <Box>
      <Content>
        <Header
          title={createCardTitle()}
          customBackUrl={getStandardUri(backUrl)}
          style={{ padding: 20 }}
        />
        <Box data-test-id="post-placement-details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Display data-test-id="post-placement-details-summary">
                <Card
                  variant="secondary"
                  content={
                    <Content padding={3}>
                      <Summary
                        summaryData={summaryData}
                        displayFilters={true}
                        isLoading={isLoadingPostPlacement}
                      ></Summary>
                    </Content>
                  }
                ></Card>
              </Display>
            </Grid>
            <Grid item xs={6} sm={6}>
              <PostPlacementSpecificData
                data={postPlacementData?.specificDataJson}
                isLoading={isLoadingPostPlacement}
                postPlacementType={postPlacementData?.requestType}
              ></PostPlacementSpecificData>
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
            <Grid item xs={6} sm={6} style={{ marginTop: -14 }}>
              <PostPlacementComment
                requestId={requestId}
                postPlacementOperations={postPlacementOperations}
                postPlacementPostOperations={postPlacementPostOperations}
              ></PostPlacementComment>
            </Grid>
          </Grid>
        </Box>
      </Content>
    </Box>
  )
}

export default PostPlacementDetail
