/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from 'react'
import { Box, Card, Grid } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { CardItemResponse } from 'src/domain/models/common'

interface ComplaintCardsProps {
  complaintGetOperations?: IComplaintGetOperations
  isLoading?: boolean
  onCardClick?: (card: CardItemResponse) => void
}

const ComplaintCards: React.FC<ComplaintCardsProps> = ({
  complaintGetOperations,
  onCardClick,
}) => {
  const [selectedCard, setSelectedCard] = useState<CardItemResponse>()

  const { data: cardsData, isLoading: loadingCardsData } = useCustomQuery(
    ['getComplaintCards'],
    async () =>
      complaintGetOperations?.getComplaintCards().then((data) => {
        setSelectedCard(data[0])
        return data
      }),
    { cacheTime: 0, enabled: true }
  )

  const totalCards = cardsData?.length ?? 4
  const cardSize = 12 / totalCards

  const cardsDataStandard =
    cardsData && cardsData.length > 0
      ? cardsData
      : Array.from({ length: 4 }, (_, index) => ({
          cardKey: `placeholder-${index}`,
          value: '',
          displayName: 'Placeholder',
        }))

  return (
    <Box pb={4} pt={3}>
      <Grid container spacing={2}>
        {cardsDataStandard?.map((card) => (
          <Grid item xs={cardSize as any} key={card?.cardKey}>
            <Card
              content={card?.value}
              footer={card?.displayName}
              onClick={() => {
                setSelectedCard(card)
                onCardClick?.(card)
              }}
              isLoading={loadingCardsData}
              variant={
                selectedCard?.cardKey === card?.cardKey
                  ? 'primary'
                  : 'secondary'
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default ComplaintCards
