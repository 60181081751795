import { fFormatDate, Grid, Typography } from 'everchain-uilibrary'
import React from 'react'

interface PostPlacementDeceasedDataParams {
  data: any
}
const PostPlacementDeceasedData: React.FC<PostPlacementDeceasedDataParams> = ({
  data,
}) => {
  return (
    <Grid>
      <Typography>Date Of Death: {fFormatDate(data?.DateOfDeath)}</Typography>
      <Typography>Notification Source: {data?.NotificationSource}</Typography>
    </Grid>
  )
}

export default PostPlacementDeceasedData
