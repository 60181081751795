import React from 'react'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeComplaintGetData } from 'src/main/factories/feature/get/Complaints'
import ComplaintSearchAccounts from 'src/presentation/pages/Complaint/SearchAccounts'

const makeComplaintSearchAccounts: React.FC = () => {
  return (
    <ComplaintSearchAccounts
      businessGetOperations={makeBusinessGetData()}
      complaintGetOperations={makeComplaintGetData()}
    />
  )
}

export default makeComplaintSearchAccounts
