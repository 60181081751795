import { ACCOUNT_DETAIL } from 'src/presentation/routes'

export enum SearchResultTypeEnum {
  ACCOUNT = 1,
}

export enum SearchFilterTypeEnum {
  ECAID = 1,
  LENDER_LOAN_ID = 2,
}

export interface SearchData {
  key: string
  searchFields: { [fieldName: string]: string }
}

export interface SearchResult {
  pageNumber: number
  pageSize: number
  totalCount: number
  data: SearchData[]
}

export interface SearchDataResponse {
  resultType: SearchResultTypeEnum
  searches: SearchResult
}

export const searchFilterTypeDescription = [
  { id: SearchFilterTypeEnum.LENDER_LOAN_ID, label: 'Lender Loan ID' },
  { id: SearchFilterTypeEnum.ECAID, label: 'ECAID' },
]

export const searchResultTypeDescription = [
  { id: SearchResultTypeEnum.ACCOUNT, description: 'Account' },
]

export const getDescriptionFromSearchResultEnum = (
  enumValue: SearchResultTypeEnum
): { id: number; description: string } => {
  const resultType = searchResultTypeDescription.find(
    (item) => item.description === enumValue.toString()
  )!
  return resultType
}

export const getRedirectionRouteSearchResultEnum = (
  key: string,
  enumValue: SearchResultTypeEnum,
  backUrl?: string | number
): string => {
  switch (enumValue) {
    case SearchResultTypeEnum.ACCOUNT:
      return `${ACCOUNT_DETAIL}/${key}/${backUrl ?? ''}`
  }
}
