export const complaintStatuses = {
  pendingDataEntry: 'PendingDataEntry',
  new: 'New',
  investigating: 'Investigating',
  pendingFinalReview: 'PendingFinalReview',
  waitingForInfoPlacer: 'WaitingForInfoPlacer',
  waitingForInfoVendor: 'WaitingForInfoVendor',
  closed: 'Closed',
  withdrawn: 'Withdrawn',
}
