/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState } from 'react'
import {
  Accordion,
  Button,
  Colors,
  DataTable,
  DataTableState,
  Flex,
  Group,
  Icon,
  ModalDialog,
  SuccessToast,
  Switch,
  TextArea,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { complaintCommentColumns } from './gridColumns'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { AuthContext } from 'src/context/AuthenticationContext'

interface ComplaintCommentsAccordionProps {
  complaintGetOperations?: IComplaintGetOperations
  complaintPostOperations?: IComplaintPostOperations
  complaintId?: number
  isLoading?: boolean
}

const ComplaintCommentsAccordion: React.FC<ComplaintCommentsAccordionProps> = ({
  complaintGetOperations,
  complaintPostOperations,
  complaintId,
  isLoading,
}) => {
  const [comment, setComment] = useState<string | undefined>(undefined)
  const [commentId, setCommentId] = useState<number | undefined>(undefined)
  const [hasError, setHasError] = useState<boolean>(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(false)

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const {
    data: commentsData,
    isFetching: loadingCommentsData,
    refetch: refetchGetQuery,
  } = useCustomQuery(
    ['getComplaintComments', gridState],
    async () =>
      complaintGetOperations?.getComplaintComments(complaintId!, gridState),
    { cacheTime: 0, enabled: !!complaintId }
  )

  const handleOnClickSave = () => {
    if (!comment || !complaintId) {
      setHasError(true)
      return
    }

    setHasError(false)
    setLoadingSaveButton(true)

    if (commentId) {
      complaintPostOperations
        ?.updateComplaintComment({ id: commentId, comment, isPublic })
        .then(() => {
          SuccessToast('Complaint updated successfully')
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
          setCommentId(undefined)
          setOpenEditModal(false)
        })
    } else {
      complaintPostOperations
        ?.saveComplaintComment({ complaintId, comment })
        .then(() => {
          SuccessToast('Complaint comment created successfully')
          setComment('')
          refetchGetQuery()
        })
        .finally(() => {
          setLoadingSaveButton(false)
        })
    }
  }

  const handleEditButton = (id: number) => {
    const commentRecord = commentsData?.data.find((x) => x.id === id)

    setCommentId(id)
    setComment(commentRecord?.text)
    setIsPublic(commentRecord?.isPublic ?? false)
    setOpenEditModal(true)
  }

  return (
    <Accordion title="Comments" icon={<Icon name="Comment" />}>
      <Group flexDirection="column">
        <Flex justifyContent="flex-end">
          <Button
            leftIcon={<Icon name="Add" fontSize="small" />}
            type="button"
            onClick={handleOnClickSave}
            isFetching={loadingSaveButton}
            isLoading={isLoading}
            disabled={hasError && !comment}
          >
            Add Comment
          </Button>
        </Flex>
        <TextArea
          placeholder="Comment"
          value={!openEditModal ? comment : ''}
          onChange={(e) => setComment(e.target.value)}
          width="100%"
          variant="secondary"
          errormessage={
            hasError && !comment && !openEditModal
              ? 'Comment is required'
              : undefined
          }
          isLoading={isLoading}
          skeletonWidth="100%"
        />

        <DataTable
          isLoading={loadingCommentsData || isLoading}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintCommentColumns(handleEditButton, isInternal)}
          gridState={gridState}
          data={commentsData?.data}
          pageable
          useFilterMenu
          total={commentsData?.totalCount}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
        />
      </Group>
      <ModalDialog
        header="Edit Comment"
        isOpen={openEditModal}
        onClose={() => {
          setComment('')
          setCommentId(undefined)
          setOpenEditModal(false)
        }}
        buttonOkText="Save"
        onContinue={handleOnClickSave}
        isFetching={loadingSaveButton}
        typeOkButton="button"
      >
        <Group color={Colors.primary} flexDirection="column">
          <Switch
            label="Is Public"
            checked={isPublic}
            onClick={() => setIsPublic(!isPublic)}
          />
          <TextArea
            placeholder="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            width="100%"
            variant="secondary"
            errormessage={
              hasError && !comment ? 'Comment is required' : undefined
            }
            isLoading={isLoading}
            skeletonWidth="100%"
          />
        </Group>
      </ModalDialog>
    </Accordion>
  )
}

export default ComplaintCommentsAccordion
