import { renderDateTime } from 'everchain-uilibrary'

export const complaintsColumns = [
  {
    field: 'id',
    title: 'ID',
    show: true,
    width: 61,
  },
  {
    field: 'ecaid',
    title: 'Account ID',
    show: true,
    width: 265,
  },
  {
    field: 'lenderLoanId',
    title: 'Loan ID',
    show: true,
    width: 150,
  },
  {
    field: 'status',
    title: 'Status',
    show: true,
    width: 150,
  },
  {
    field: 'placerName',
    title: 'Placer',
    show: true,
    width: 180,
    notFilterable: true,
  },
  {
    field: 'vendorName',
    title: 'Vendor',
    show: true,
    width: 180,
    notFilterable: true,
  },
  {
    field: 'createdAt',
    title: 'Created At',
    show: true,
    width: 150,
    render: renderDateTime,
  },
]
