/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState } from 'react'
import {
  Button,
  Flex,
  FormSubmitButton,
  Group,
  Icon,
} from 'everchain-uilibrary'
import { ComplaintDto } from 'src/domain/models/complaint'
import TypographyComplaintActions from './TypographyComplaintActions'
import { useHistory } from 'react-router-dom'
import { complaintStatuses } from '../../complaintHelper'
import ComplaintAssignUserModal from './ComplaintAssignUserModal'
import { useCustomQuery } from 'src/infra/reactQuery'
import { INTERNAL, USER_ID } from 'src/configs/AuthService'
import { AuthContext } from 'src/context/AuthenticationContext'
import { IClientGetOperations } from 'src/domain/features/get/clients/clients'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface ComplaintActionsProps {
  complaintDto?: ComplaintDto
  isLoading?: boolean
  isEditing: boolean
  isLoadingSaveButton?: boolean
  clientGetOperations?: IClientGetOperations
  complaintPostOperations?: IComplaintPostOperations
}

const ComplaintActions: React.FC<ComplaintActionsProps> = ({
  complaintDto,
  isLoading,
  isEditing,
  isLoadingSaveButton,
  clientGetOperations,
  complaintPostOperations,
}) => {
  const [openAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false)
  const { userPermissions, user, isCreditor } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const userId = user.profile[USER_ID]
  const isInternal = userPermissions.type.toLowerCase() === INTERNAL
  const history = useHistory()

  const { data: clientUsers, isLoading: loadingClientUsers } = useCustomQuery(
    ['getUserBusiness', userId],
    async () => clientGetOperations?.getUserBusiness(userId),
    { cacheTime: 0, enabled: isInternal }
  )

  const orderedClientUsers = clientUsers?.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  })

  const canRenderCreditorWithdraw =
    isEditing &&
    isCreditor &&
    ![
      complaintStatuses.closed,
      complaintStatuses.withdrawn,
      complaintStatuses.pendingFinalReview,
    ].includes(complaintDto?.status ?? '')

  const canRenderNonCreditorWithdraw =
    isEditing &&
    !isCreditor &&
    complaintDto?.status === complaintStatuses.pendingDataEntry

  const canRenderWithdraw =
    canRenderCreditorWithdraw || canRenderNonCreditorWithdraw

  const canSaveComplaint = ability.can(
    PermissionCodeAccess.Recover_Complaint_CreateComplaint,
    'any'
  )

  const canRespondComplaint = ability.can(
    PermissionCodeAccess.Recover_Complaint_RespondComplaint,
    'any'
  )

  return (
    <Flex justifyContent="space-between" pb={3} alignItems="center">
      <Group>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>ID:</strong> {complaintDto?.id || 'New'}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Placer:</strong> {complaintDto?.placerName}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Status:</strong> {complaintDto?.status}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Vendor:</strong> {complaintDto?.vendorName}
        </TypographyComplaintActions>
      </Group>
      <Group>
        <Button
          variant="secondary"
          type="button"
          leftIcon={<Icon name="Block" fontSize="small" />}
          isLoading={isLoading}
          disabled={isLoadingSaveButton}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        {((isEditing && canRespondComplaint) ||
          (!isEditing && canSaveComplaint)) && (
          <>
            {isInternal &&
              complaintDto?.status !== complaintStatuses.closed &&
              complaintDto?.status !== complaintStatuses.withdrawn && (
                <Button
                  type="button"
                  leftIcon={<Icon name="PersonAdd" fontSize="small" />}
                  isLoading={isLoading}
                  disabled={isLoadingSaveButton || !complaintDto?.id}
                  onClick={() => setOpenAssignUserModal(true)}
                >
                  Assign Analyst
                </Button>
              )}
            <FormSubmitButton
              text="Save"
              leftIcon={<Icon name="Save" fontSize="small" />}
              isLoading={isLoading}
              isFetching={isLoadingSaveButton}
            />
          </>
        )}
      </Group>
      {isEditing &&
        canRespondComplaint &&
        (canRenderWithdraw ||
          complaintDto?.status === complaintStatuses.pendingDataEntry) && (
          <Group pr={3}>
            <>
              {canRenderWithdraw && (
                <Button
                  variant="secondary"
                  type="button"
                  leftIcon={<Icon name="RemoveCircle" fontSize="small" />}
                  isLoading={isLoading}
                  disabled={isLoadingSaveButton}
                >
                  Withdraw
                </Button>
              )}
              {complaintDto?.status === complaintStatuses.pendingDataEntry && (
                <Button
                  type="button"
                  leftIcon={<Icon name="AddCircle" fontSize="small" />}
                  isLoading={isLoading}
                  disabled={isLoadingSaveButton}
                >
                  Submit
                </Button>
              )}
            </>
          </Group>
        )}
      <ComplaintAssignUserModal
        isOpen={openAssignUserModal}
        clientUsers={orderedClientUsers}
        setIsOpen={setOpenAssignUserModal}
        loadingClientUsers={loadingClientUsers}
        complaintDto={complaintDto}
        complaintPostOperations={complaintPostOperations}
      />
    </Flex>
  )
}

export default ComplaintActions
